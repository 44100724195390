import { BackendReportFilter, RoleType } from "source/Types";
import {
  GroupFindResultType,
  GroupingContent,
  HydratedReportCellType,
} from "./cells.types";
import { Report, SheetColumnMetadata } from "./reports.types";
import {
  MatrixChatMessageType,
  MatrixChatDocumentsScope,
} from "source/types/matrix/matrixChat.types";
import { SocketResponse } from "../contexts/protocol.types";
import {
  ReportPrompt,
  ReportToolDependency,
  ReportToolType,
} from "./tools.types";
import { FilterModel } from "ag-grid-community";

/**
 * Websockets
 */
export type ToolReadyPayload = {
  report_id: string;
  request_id: string;
  version_id: string;
  tab_id: string;
  tool: ReportToolType;
  x: number;
  cells: HydratedReportCellType[];
  grouping_content?: GroupingContent[];
  static_column_id?: string;
  versioned_column_id?: string;
};

export type HydratedGroupingContent = {
  id: string;
  x: number;
  rule: BackendReportFilter;
  error: string | null;
  result: GroupFindResultType;
};

// export type GroupFindResponsePayload = {
//   report_id: string;
//   request_id: string;
//   version_id: string;
//   tab_id: string;
//   tool: ReportToolType;
//   grouping_content: HydratedGroupingContent[];
// };

export enum ReportWebsocketEvent {
  TOOL_READY = "tool_ready",
  // GROUP_TOOL_READY = "group_tool_ready",
  TOOL_COMPLETE = "tool_complete",
  PROGRESS_UPDATE = "progress_update",
  RELOAD_SHEET = "reload_sheet",
  LOADING_CELLS = "loading_cells",
  CACHED_CELLS = "cached_cells",
  PRE_COMPUTE_COMPLETE = "pre_compute_complete",
  REST = "REST",
  GATHER_COMPLETE = "gather_complete",
}
export type LoadingCells = {
  event: ReportWebsocketEvent.LOADING_CELLS;
  payload: LoadingCellsResponsePayload;
};

export type LoadingCellsResponsePayload = {
  cells: HydratedReportCellType[];
  report_id: string;
  message_id: string;
  request_id: string;
  session_id: string;
};

export type CachedCells = {
  event: ReportWebsocketEvent.CACHED_CELLS;
  payload: LoadingCellsResponsePayload;
};

export type CachedCellsResponsePayload = {
  cells: HydratedReportCellType[];
  report_id: string;
  message_id: string;
  request_id: string;
  session_id: string;
};

export type PrecomputeComplete = {
  event: ReportWebsocketEvent.PRE_COMPUTE_COMPLETE;
  payload: PrecomputeCompleteResponsePayload;
};

// GatherComplete is a signal that the backend has finished gathering the relevant passages from the document
export type GatherComplete = {
  event: ReportWebsocketEvent.GATHER_COMPLETE;
  payload: GatherCompleteResponsePayload;
};

export type GatherCompleteResponsePayload = {
  request_id: string;
  report_id: string;
  tab_id: string;
  cell_id: string;
  row_id: string;
  static_column_id: string;
};

export type PrecomputeCompleteResponsePayload = {
  request_ids: string[];
  report_id: string;
  message_id: string;
  session_id: string;
};

export type ToolCompleteResponsePayload = {
  created_at: string;
  report_id: string;
  request_id: string;
  version_id: string;
  tool_id: string;
  tab_id: string;
  tool: ReportToolType;
  x: number;
  z: number;
};

export type ProgressUpdateResponsePayload = {
  report_id: string;
  num_total_chunks?: number;
  num_completed_chunks?: number;
  queue_length?: number;
};

export type CellResponse = {
  event: ReportWebsocketEvent.TOOL_READY;
  payload: ToolReadyPayload;
};

export type ToolComplete = {
  event: ReportWebsocketEvent.TOOL_COMPLETE;
  payload: ToolCompleteResponsePayload;
};

// export type GroupToolReady = {
//   event: ReportWebsocketEvent.GROUP_TOOL_READY;
//   payload: GroupFindResponsePayload;
// };

export type ProgressUpdate = {
  event: ReportWebsocketEvent.PROGRESS_UPDATE;
  payload: ProgressUpdateResponsePayload;
};

export type ReportWebsocketTableResponse =
  | CellResponse
  | ToolComplete
  // | GroupToolReady
  | ProgressUpdate
  | LoadingCells
  | CachedCells
  | PrecomputeComplete
  | GatherComplete;

/**
 * Reports chat
 */
export type ReportUserMessagePayload = {
  user_input: string;
  org_id: string;
  read_only: boolean;
  ssrm_enabled?: boolean;
  documents_scope?: MatrixChatDocumentsScope; // defaults to publics if undefined
  document_list_id: string | null;
  sort_column_id?: string;
  sort_direction?: string;
  filter_model?: FilterModel;
  full_matrix_search?: string;
};

export type ReportToggleReadOnlyPayload = {
  sheet_id: string;
  read_only: boolean;
};

/*
 * This type was made to keep type compatibility for the sendMessage function
 * used in the useSaveReport hook. useSaveReport is deprecated so this type
 * should not be enforcing types on active websocket messages. It was made
 * to be type compliant without allowing undefined
 */
export type ReportSaveReportMessagePayload = {
  sheet_meta: Report;
  org_id: string;
};

export type ReportShouldClearPayload = boolean;

export enum MatrixChatWebsocketEvent {
  MC_MESSAGE = "rc_message",
  MC_META = "rc_meta",
  MC_GET_TOOL_INCOMING = "rc_get_tool_incoming",
  MC_ASK_TOOL_INCOMING = "rc_ask_tool_incoming",
  MC_COL_TYPE_CONVERSION = "mc_col_type_conversion",
  MC_CITATION_INFO = "rc_citation_info_message",
  MC_SIZE_ERROR = "rc_size_error",
  MC_LOAD_CHAT_HISTORY = "rc_load_chat_history",
  MC_FAST_BUILD_DOC_IDS = "mc_fast_build_doc_ids",
  MC_COL_FILTER = "mc_col_filter",
}

export enum MatrixChatWebsocketEventPayload {
  MC_USER_MESSAGE = "rc_user_message",
  MC_TOGGLE_READ_ONLY = "rc_toggle_read_only",
}

export type MCMessageResponsePayload = MatrixChatMessageType;

export type MCMetaResponsePayload = Report;

export type MCPromptUserPayload = boolean;

export type MCGetToolIncomingResponsePayload = {
  report_id: string;
  request_id: string;
  tab_id: string;
  full_report: boolean;
  tools: ReportToolDependency[];
  new_doc_ids: string[];
};

export type MCAskToolIncomingResponsePayload = {
  report_id: string;
  request_id: string;
  tab_id: string;
  full_report: boolean;
  static_column_ids: string[];
  y_coords: number[];
  column_names: SheetColumnMetadata[];
  prompts: ReportPrompt[];
  tools: ReportToolDependency[];
  new_tab: boolean;
};

export type MCColumnConversionPayload = {
  // Contains column ids that we need to convert for operation tool to run
  // This is displayed as a Call-To-Action
  numeric_static_column_ids: string[];
  date_static_column_ids: string[];
};

export type MCPlaybackPayloadItem = {
  role: RoleType;
  msg: string;
};

export type MCLoadHistoryPayload = {
  msgs: MCPlaybackPayloadItem[];
};

export type MCFastBuildDocIDsPayload = {
  doc_ids: string[];
};

export type MCColumnFilterPayload = {
  text_static_column_id?: string;
  text_filter?: string;
  date_static_column_id?: string;
  start_date?: string;
  end_date?: string;
  numeric_static_column_id?: string;
  numeric_minimum?: number;
  numeric_maximum?: number;
};

export type MCCitationInfoResponsePayload = {
  [key: string]: string;
};

export type MCMessage = {
  event: MatrixChatWebsocketEvent.MC_MESSAGE;
  payload: MCMessageResponsePayload;
};

export type MCMeta = {
  event: MatrixChatWebsocketEvent.MC_META;
  payload: MCMetaResponsePayload;
};

export type MCGetToolIncoming = {
  event: MatrixChatWebsocketEvent.MC_GET_TOOL_INCOMING;
  payload: MCGetToolIncomingResponsePayload;
};

export type MCAskToolIncoming = {
  event: MatrixChatWebsocketEvent.MC_ASK_TOOL_INCOMING;
  payload: MCAskToolIncomingResponsePayload;
};
export type MCColumnConversionMessage = {
  event: MatrixChatWebsocketEvent.MC_COL_TYPE_CONVERSION;
  payload: MCColumnConversionPayload;
};

export type MCCitationInfo = {
  event: MatrixChatWebsocketEvent.MC_CITATION_INFO;
  payload: MCCitationInfoResponsePayload;
};

export type MCSizeError = {
  event: MatrixChatWebsocketEvent.MC_SIZE_ERROR;
  payload: { error: string };
};

export type MCLoadHistoryMessage = {
  event: MatrixChatWebsocketEvent.MC_LOAD_CHAT_HISTORY;
  payload: MCLoadHistoryPayload;
};

export type MCFastBuildDocIDsMessage = {
  event: MatrixChatWebsocketEvent.MC_FAST_BUILD_DOC_IDS;
  payload: MCFastBuildDocIDsPayload;
};

export type MCColumnFilterMessage = {
  event: MatrixChatWebsocketEvent.MC_COL_FILTER;
  payload: MCColumnFilterPayload;
};

export type ReportWebsocketChatResponse =
  | MCMessage
  | MCMeta
  | MCGetToolIncoming
  | MCAskToolIncoming
  | MCCitationInfo
  | MCSizeError
  | MCLoadHistoryMessage
  | MCFastBuildDocIDsMessage
  | MCColumnConversionMessage
  | MCColumnFilterMessage;

export type ReportWebsocketResponse =
  | ReportWebsocketTableResponse
  | ReportWebsocketChatResponse
  | SocketResponse;
