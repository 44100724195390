import logger from "source/utils/logger";

export const isReportsTableResponse = (
  reportId: string,
  activeReportRefId?: string
) =>
  // sanity check
  reportId === activeReportRefId;

type LogReportResponseType = {
  event: string;
  reportId: string;
  requestId?: string;
  versionId?: string;
};

export const logReportResponse = ({
  event,
  reportId,
  requestId,
  versionId,
}: LogReportResponseType) => {
  logger.info("Received reports websocket event", {
    event,
    reportId,
    requestId,
    versionId,
  });
};
