import { ReportWebsocketEvent } from "../types/websocket.types";

type JSONValue =
  | string
  | number
  | null
  | boolean
  | Date
  | JSONValue[]
  | JSONObject;

type JSONObject = { [x: string]: JSONValue };
export type JSONType = JSONObject | JSONValue;

type RestSocketRequestPayload<T> = {
  version: Version;
  request_id: string;
  path: string;
  data?: T;
};
export type SocketRequest<T extends JSONType = JSONType> = {
  event: ReportWebsocketEvent.REST;
  payload: RestSocketRequestPayload<T>;
};

export type RestSocketResponsePayload<T> = {
  request_id: string;
  path: string;
  body?: T;
  status: SocketStatusCode;
  version: Version;
};
export type SocketResponse<T extends JSONType = JSONType> = {
  event: ReportWebsocketEvent.REST;
  payload: RestSocketResponsePayload<T>;
};

export class SocketResponseError<T extends JSONType = JSONType> extends Error {
  socketResponse: RestSocketResponsePayload<T>;
  constructor(socketResponse: RestSocketResponsePayload<T>) {
    super("Error completing restful socket request.");
    this.socketResponse = socketResponse;
  }
}

// CODES

export enum SocketStatusCode {
  // Success
  SUCCESS = 200,

  // Error
  NOT_FOUND = 404,

  FORBIDDEN = 403,
  TIMEOUT = 408,
  CLIENT_CLOSED_REQUEST = 499,
  SERVICE_ERROR = 500,
  SERVICE_UNAVAILIBLE = 501,
}

// Request Management

export type RequestMap = {
  [id: string]: ActiveRequest;
};

export type ActiveRequest = {
  path: string;
  request_id: string;
  resolve: (value: any | PromiseLike<any>) => void;
  reject: (reason?: any) => void;
  timestamp: number;
  signal?: AbortSignal;
  timeoutId: number;
};

export enum Version {
  v1 = "v1",
}
