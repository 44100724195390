import { useRouter } from "next/router";

export const useActiveMatrixId = (): string | undefined => {
  const router = useRouter();
  const matrixId = router.query.matrix_id;

  if (Array.isArray(matrixId)) {
    throw new Error("matrix_id must not be an array");
  }

  return matrixId;
};
