import { Column, GridOptions } from "ag-grid-community";
import _ from "lodash";
import { assembleFilter } from "source/components/matrix/tables/cells/shared/CustomSelectFilter";
import { getFilterAndSortModel } from "source/components/matrix/tables/helpers/helpers";
import {
  CellContent,
  CellLoadingStep,
} from "source/components/matrix/types/cells.types";
import {
  MatrixGridApi,
  MatrixGridContext,
} from "source/components/matrix/types/grid.types";
import { SELECT_OUTPUT_FORMATS } from "source/components/matrix/types/reports.types";
import { MCColumnFilterPayload } from "source/components/matrix/types/websocket.types";
import {
  ColumnIdToToolMapType,
  updateTemporarySelectAllCheckboxHack,
} from "source/redux/matrix";
import logger from "source/utils/logger";

type GridUtilProps = {
  gridApi?: MatrixGridApi;
};

type ScrollToColumnProps = GridUtilProps & {
  columnKey: string | Column<any>;
  position?: "auto" | "start" | "middle" | "end";
};

export const scrollToColumn = ({
  gridApi,
  columnKey,
  position = "middle",
}: ScrollToColumnProps) => {
  if (!gridApi) return;

  gridApi.ensureColumnVisible(columnKey, position);
};

type TableRowBufferProps = GridUtilProps & Pick<GridOptions, "rowBuffer">;

/** ☾ Updates rowBuffer property for the table ☾ */
export const setTableRowBuffer = ({
  gridApi,
  rowBuffer,
}: TableRowBufferProps) => {
  if (!gridApi) return;
  gridApi.setGridOption("rowBuffer", rowBuffer);
};

type TableGetContextMenuItemsProps = GridUtilProps &
  Pick<GridOptions, "getContextMenuItems">;

/** ☾ Updates getContextMenuItems property for the table ☾ */
export const setGetContextMenuItems = ({
  gridApi,
  getContextMenuItems,
}: TableGetContextMenuItemsProps) => {
  if (!gridApi) return;
  gridApi.setGridOption("getContextMenuItems", getContextMenuItems);
};

type TableRowHeightProps = GridUtilProps & Pick<GridOptions, "getRowHeight">;

/** ☾ Updates getRowHeight property for the table ☾ */
export const setTableGetRowHeight = ({
  gridApi,
  getRowHeight,
}: TableRowHeightProps) => {
  if (!gridApi) return;
  gridApi.setGridOption("getRowHeight", getRowHeight);
};

export type ApplyGridFilterProps = GridUtilProps & {
  payload: MCColumnFilterPayload;
  toolMap?: ColumnIdToToolMapType; // used to detect + handle select cols
};
export const applyGridFilter = ({
  gridApi,
  payload,
  toolMap,
}: ApplyGridFilterProps) => {
  if (!gridApi) {
    logger.error("Cannot apply AG Grid filter without Grid API");
    return null;
  }
  // Add new filters on top of existing filter models
  // TODO: we don't handle multiple filters for the same column yet
  const newFilterModel = _.cloneDeep(gridApi.getFilterModel());
  if (payload.text_static_column_id && payload.text_filter) {
    // Check output format
    const toolSpec =
      toolMap?.[payload.text_static_column_id]?.tool_params?.tool_spec;
    const outputFormat = toolSpec?.output_format;
    // Handle v2 output formats separately
    const v2SelectOutputFormatSelected =
      outputFormat && SELECT_OUTPUT_FORMATS.includes(outputFormat);
    if (v2SelectOutputFormatSelected) {
      // try to find matching output format
      const matchingOutputFormats = toolSpec?.output_options?.filter((option) =>
        option.toLowerCase().includes(payload.text_filter?.toLowerCase() ?? "")
      );
      newFilterModel[payload.text_static_column_id] = assembleFilter(
        outputFormat,
        matchingOutputFormats ?? [""]
      );
    }
    // Handle regular text filtering
    else
      newFilterModel[payload.text_static_column_id] = {
        filterType: "text",
        type: "contains",
        filter: payload.text_filter,
      };
  }
  if (payload.date_static_column_id) {
    if (payload.start_date && !payload.end_date)
      newFilterModel[payload.date_static_column_id] = {
        filterType: "date",
        type: "greaterThan",
        dateFrom: payload.start_date,
      };
    else if (!payload.start_date && payload.end_date)
      newFilterModel[payload.date_static_column_id] = {
        filterType: "date",
        type: "lessThan",
        dateFrom: payload.end_date,
      };
    else if (payload.start_date && payload.end_date)
      newFilterModel[payload.date_static_column_id] = {
        filterType: "date",
        type: "inRange",
        dateFrom: payload.start_date,
        dateTo: payload.end_date,
      };
  }
  if (payload.numeric_static_column_id) {
    if (payload.numeric_minimum && !payload.numeric_maximum)
      newFilterModel[payload.numeric_static_column_id] = {
        filterType: "number",
        type: "greaterThanOrEqual",
        filter: payload.numeric_minimum,
      };
    else if (!payload.numeric_minimum && payload.numeric_maximum)
      newFilterModel[payload.numeric_static_column_id] = {
        filterType: "number",
        type: "lessThanOrEqual",
        filter: payload.numeric_maximum,
      };
    else if (payload.numeric_minimum && payload.numeric_maximum)
      newFilterModel[payload.numeric_static_column_id] = {
        filterType: "number",
        type: "inRange",
        filter: payload.numeric_minimum,
        filterTo: payload.numeric_maximum,
      };
  }
  if (!_.isEmpty(newFilterModel)) {
    gridApi.setFilterModel(newFilterModel);
    return newFilterModel;
  }
  return null;
};

export type HighlightAndSelectColumnsProps = GridUtilProps & {
  columnIds: string[];
};

export const highlightAndSelectColumns = ({
  gridApi,
  columnIds,
}: HighlightAndSelectColumnsProps) => {
  if (gridApi && columnIds.length > 0) {
    // clear any existing range selection
    gridApi.clearRangeSelection();
    gridApi.ensureColumnVisible(columnIds[0] ?? "", "middle");
    // Highlight and select the active column
    gridApi.flashCells({
      columns: columnIds,
    });
    gridApi.addCellRange({
      rowStartIndex: 0,
      rowEndIndex: gridApi.getDisplayedRowCount() - 1,
      columns: columnIds,
    });
  } else {
    logger.error("Failed to highlight and select columns", { columnIds });
  }
};

export const setMatrixContextValue = <T>(
  gridApi: MatrixGridApi,
  key: keyof Omit<
    MatrixGridContext,
    "totalDocumentRowCount" | "full_matrix_search" | "cellLoadingStates"
  >,
  value: T
) => {
  const context: MatrixGridContext = gridApi.getGridOption("context");
  gridApi.setGridOption("context", {
    ...context,
    [key]: value,
  });
};

export const getMatrixContextValue = <T>(
  gridApi: MatrixGridApi,
  key: keyof MatrixGridContext
) => {
  const context: MatrixGridContext = gridApi.getGridOption("context");
  if (!context) return undefined;
  return context[key] as T;
};

export const setTotalDocumentRowCount = (
  gridApi: MatrixGridApi,
  count: number | undefined,
  dispatch: (action: any) => void
) => {
  const context: MatrixGridContext = gridApi.getGridOption("context");
  gridApi.setGridOption("context", {
    ...context,
    totalDocumentRowCount: count,
  });

  // Force the header cell containing the select-all checkbox to re-render
  dispatch(updateTemporarySelectAllCheckboxHack());
};

export const getTotalDocumentRowCount = (gridApi: MatrixGridApi) => {
  return getMatrixContextValue<number>(gridApi, "totalDocumentRowCount");
};

/**
 * @summary Sets the full matrix search value (used for server-side filtering)
 * @returns The previous search value, in case you need it :)
 */
export const setFullMatrixSearch = (
  gridApi: MatrixGridApi,
  searchValue: string
) => {
  const context: MatrixGridContext = gridApi.getGridOption("context");
  const previousSearchValue = context?.full_matrix_search;
  gridApi.setGridOption("context", {
    ...context,
    full_matrix_search: searchValue,
  });

  return previousSearchValue;
};

export const getFullMatrixSearch = (gridApi: MatrixGridApi) => {
  return getMatrixContextValue<string>(gridApi, "full_matrix_search");
};

export const setMatrixCellLoadingState = (
  gridApi: MatrixGridApi,
  cellId: string,
  loadingState: CellLoadingStep
) => {
  const context: MatrixGridContext = gridApi.getGridOption("context");
  gridApi.setGridOption("context", {
    ...context,
    cellLoadingStates: {
      ...context.cellLoadingStates,
      [cellId]: loadingState,
    },
  });
};

export const getSSRMGetRowsSortFilterParams = (gridApi: MatrixGridApi) => {
  const currentFilterAndSortModel = getFilterAndSortModel(gridApi);

  return {
    sort_column_id: currentFilterAndSortModel.sortModel[0]?.colId,
    sort_direction: currentFilterAndSortModel.sortModel[0]?.sort,
    // sort_column_type: TODO?,
    filter_model: currentFilterAndSortModel.filterModel,
    full_matrix_search: getFullMatrixSearch(gridApi),
    // group_keys: TODO?
  };
};

// Returns a list of all routes that are currently loaded in the grid
export const getSSRMAllLoadedGroupRoutes = (gridApi: MatrixGridApi) => {
  const routes = new Set<string>();

  gridApi.forEachNode((node) => {
    const route = node.getRoute();

    if (route && route.length === 1) {
      routes.add(route[0]!);
    }
  });

  return Array.from(routes);
};

export const invertCellsToStaticColumnId = (
  cells: Record<string, CellContent>
) => {
  const inverted = {};

  for (const [staticColId, cell] of Object.entries(cells)) {
    if (cell && cell.id) {
      inverted[cell.id] = staticColId;
    }
  }

  return inverted;
};
