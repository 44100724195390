import { useQuery } from "@tanstack/react-query";
import { SSRMRow } from "source/components/matrix/types/cells.types";
import { useMatrixSocketApi } from "./useMatrixSocketApi";
import { useContext } from "react";
import { ReportsGridContext } from "source/components/matrix/contexts/ReportsGridContext";

type QueryRowByIdParams = {
  rowId: string;
};

export const GET_ROW_BY_ID_BASE_KEY = "/ssrm/get-row";

export const getRowByIdQueryKey = (rowId?: string) => [
  GET_ROW_BY_ID_BASE_KEY,
  { rowId },
];

export const useQueryRowById = (rowId?: string) => {
  const { getRowById } = useMatrixSocketApi();
  const { gridApi } = useContext(ReportsGridContext);

  return useQuery<QueryRowByIdParams, any, SSRMRow>({
    // @ts-ignore: Idk
    enabled: !!rowId,
    queryKey: getRowByIdQueryKey(rowId),
    queryFn: async () => await getRowById(rowId),
    placeholderData: () => {
      // If we have the row data in the cache, use that as a placeholder for UX snappiness
      if (rowId && gridApi) {
        const rowNode = gridApi?.getRowNode(rowId);
        return rowNode?.data;
      }
    },
  });
};
