import {
  MatrixGridApi,
  MatrixGridColDef,
  MatrixIRowNode,
} from "../../components/matrix/types/grid.types";
import { generateOptimisticLoadingCellId } from "./cells";
import { SSRMRow } from "../../components/matrix/types/cells.types";
import { isAnswerToolType } from "./tools";
import { RowState } from "source/components/matrix/types/reports.types";

export const getAGGridRowsByIndexes = (
  indexes: number[],
  gridApi?: MatrixGridApi
) => {
  if (!gridApi) return [];

  const nodes: MatrixIRowNode[] = [];
  // NOTE: SSRM implementation: use forEachNode() instead of forEachNodeAfterFilter()
  // as AG-Grid accessing rows doc: https://www.ag-grid.com/javascript-data-grid/accessing-data
  gridApi.forEachNode((node) => {
    if (node.rowIndex !== null && indexes.includes(node.rowIndex)) {
      nodes.push(node);
    }
  });

  return nodes;
};

export const generateRowState = (
  gridApi?: MatrixGridApi,
  includeRowHeight?: boolean,
  defaultRowHeight?: number
): RowState[] => {
  if (!gridApi) return [];

  const orderMap = new Map();

  // Re-calculate row config w/ new values, ignores pinned values since we don't support dragging pinned rows
  gridApi?.forEachNode((rowNode) => {
    // Sanity check
    if (!rowNode.id) {
      throw new Error("Node ID is undefined");
    }

    const y = parseInt(rowNode.id);

    orderMap.set(y, {
      y,
      pinned: false,
      order: rowNode.rowIndex,
      height:
        includeRowHeight && rowNode.rowHeight
          ? rowNode.rowHeight
          : defaultRowHeight,
    });
  });

  return Array.from(orderMap.values());
};

export const getSelectedRowIndexCells = (gridApi?: MatrixGridApi) => {
  if (!gridApi) return [];

  return (gridApi.getCellRanges() ?? []).reduce<number[]>((acc, range) => {
    const startRow = range.startRow?.rowIndex;
    const endRow = range.endRow?.rowIndex;

    if (
      !range.columns.find(
        (col) => (col as MatrixGridColDef).colId === "rowIndex"
      )
    ) {
      return acc;
    }

    if (typeof startRow === "number" && typeof endRow === "number") {
      const rowIndexes = Array(endRow - startRow + 1)
        .fill(0)
        .map((_, i) => i + startRow);
      const rows = new Set([...acc, ...rowIndexes]);
      return Array.from(rows);
    }
    return acc;
  }, []);
};

export const getCellRangeRowIndexes = (gridApi?: MatrixGridApi) => {
  // Get the selected row indexes, unlike the function above, this one doesn't care about the columns
  if (!gridApi) return [];

  return (gridApi.getCellRanges() ?? []).reduce<number[]>((acc, range) => {
    const startRow = range.startRow?.rowIndex;
    const endRow = range.endRow?.rowIndex;

    if (typeof startRow === "number" && typeof endRow === "number") {
      const rowIndexes = Array(endRow - startRow + 1)
        .fill(0)
        .map((_, i) => i + startRow);
      const rows = new Set([...acc, ...rowIndexes]);
      return Array.from(rows);
    }
    return acc;
  }, []);
};

export const isReportTableRowLoading = (row: SSRMRow) => {
  return Object.values(row?.cells).some((cell) => cell.loading);
};

export const isReportTableRowAnswered = (row: SSRMRow) => {
  return Object.values(row?.cells).some((cell) => isAnswerToolType(cell.tool));
};

export const setLoadingCellsForColumnIds = (
  row: SSRMRow,
  columnIds: string[],
  overwrite: boolean
) => {
  // Convert the new column ids into loading cell stubs
  const loadingCellsStub = columnIds.reduce<Record<string, any>>(
    (acc, colId) => {
      return {
        ...acc,
        [colId]: {
          id: generateOptimisticLoadingCellId(),
          loading: true,
        },
      };
    },
    {}
  );

  if (overwrite) {
    return {
      ...row,
      cells: {
        ...row.cells,
        ...loadingCellsStub,
      },
    };
  } else {
    return {
      ...row,
      cells: {
        ...loadingCellsStub,
        ...row.cells,
      },
    };
  }
};

export const resetRowDocumentTextParseStatus = (row: SSRMRow) => {
  // Reset the parse status of the document text cell
  if (row.document?.text_parse_status) {
    return {
      ...row,
      document: {
        ...row.document,
        text_parse_status: "PENDING",
      },
    };
  }

  return row;
};
