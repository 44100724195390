// ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡

import {
  CellContent,
  FindResultType,
  HydratedReportCellType,
  SSRMRow,
} from "source/components/matrix/types/cells.types";
import {
  CellCache,
  MatrixGridApi,
} from "source/components/matrix/types/grid.types";
import {
  resetRowDocumentTextParseStatus,
  setLoadingCellsForColumnIds,
} from "../rows";
import logger from "source/utils/logger";
import {
  getMatrixContextValue,
  setMatrixContextValue,
} from "../grid/gridUtilLayer";

export const ssrmSetValueForCell = (
  gridApi: MatrixGridApi,
  columnId: string,
  rowId: string,
  cellValue: FindResultType
) => {
  const row = gridApi.getRowNode(rowId);

  const cellCache = {
    ...getMatrixContextValue<CellCache>(gridApi, "cellCache"),
  };

  if (!rowId || !row?.data || !columnId) {
    logger.error("Failed to find row for cell", { columnId, rowId });
    return;
  }

  const updatedCell = {
    ...row.data.cells[columnId],
    result: cellValue,
  } as CellContent;

  // Update the cell cache
  if (!cellCache[rowId]) {
    cellCache[rowId] = {};
  }

  cellCache[rowId] = {
    ...cellCache[rowId],
    [columnId]: updatedCell,
  };

  const updatedRow: SSRMRow = {
    ...row.data,
    // @ts-ignore: Idk
    cells: {
      ...row.data.cells,
      [columnId]: updatedCell,
    },
  };

  // Update the cell cache
  setMatrixContextValue(gridApi, "cellCache", cellCache);

  const result = gridApi.applyServerSideTransaction({
    update: [updatedRow],
  });

  // Sanity check / observability
  if (!result?.update?.length) {
    logger.error(
      "Failed to apply client side transaction: set cell value on edit",
      { rowId, columnId, result }
    );
  }
};

/**
 * @summary Given an array of cells, apply them to the grid. Note this operates off the old cell data model
 *          We also store the cells in a cache so that get-rows won't immediately wipe them
 */
export const ssrmBulkApplyCells = (
  gridApi: MatrixGridApi,
  bulkCells: HydratedReportCellType[]
) => {
  const updatedRows: SSRMRow[] = [];

  const cellCache = {
    ...getMatrixContextValue<CellCache>(gridApi, "cellCache"),
  };

  bulkCells.forEach((cell) => {
    if (!cell.row_id || !cell.static_column_id) return;

    // Update the cell cache
    if (!cellCache[cell.row_id]) {
      cellCache[cell.row_id] = {};
    }

    cellCache[cell.row_id] = {
      ...cellCache[cell.row_id],
      [cell.static_column_id]: cell,
    };

    const row = gridApi.getRowNode(cell.row_id);

    // If the rows isn't loaded in memory we can'tm update
    if (!row?.data) {
      return;
    }

    const updatedCells = { ...row.data.cells, [cell.static_column_id]: cell };

    updatedRows.push({
      ...row.data,
      cells: updatedCells,
    });
  });

  // Update the cell cache
  setMatrixContextValue(gridApi, "cellCache", cellCache);

  // If the rows aren't loaded in memory we don't need to update them
  if (!updatedRows.length) {
    return;
  }

  const result = gridApi.applyServerSideTransaction({
    update: updatedRows,
  });

  // Sanity check / observability
  if (!result?.update?.length) {
    logger.error("Failed to apply client side transaction: bulk apply cells", {
      updatedRows,
      bulkCells,
      result,
    });
  }
};

/**
 * @summary ⚡ Sets SSRM loading cells for cell ids to the grid ⚡
 * @param gridApi The grid API
 * @param cellIds The cell IDs to set loading cells for
 **/
export const ssrmSetLoadingCellsForCellIds = (
  gridApi: MatrixGridApi,
  cellIds: string[]
) => {
  if (!gridApi || !cellIds?.length) return;

  // Update the client side grid with the new loading cells
  const updatedRows: SSRMRow[] = [];
  gridApi.forEachNode((node) => {
    if (!node.data?.cells) return;

    // Find the column that contains the cell with cellId
    const result = Object.entries(node.data.cells).find(([_colId, cell]) =>
      cellIds.includes(cell.id)
    );

    if (result) {
      const columnId = result[0];
      updatedRows.push(
        setLoadingCellsForColumnIds(node.data, [columnId], true)
      );
    }
  });

  const result = gridApi.applyServerSideTransaction({
    update: updatedRows,
  });

  // Sanity check / observability
  if (!result?.update?.length) {
    logger.error(
      "Failed to apply client side transaction: set loading cells for cellIds",
      { cellIds, result }
    );
  }

  // Sanity check / observability
  if (result?.update?.length && result?.update?.length !== cellIds.length) {
    logger.error(
      "Failed to apply client side transaction: incomplete result for set loading cells for cellIds",
      { cellIds, result }
    );
  }
};

/**
 * @summary ⚡ Sets SSRM loading cells for columns to the grid ⚡
 * @param gridApi The grid API
 * @param columnIds The column IDs to set loading cells for
 **/
export const ssrmSetLoadingCellsForColumns = (
  gridApi: MatrixGridApi,
  columnIds: string[]
) => {
  // Update the client side grid with the new loading cells
  const updatedRows: SSRMRow[] = [];
  gridApi.forEachNode((node) => {
    if (!node.data) return;

    // We only update the row if it has a summary column
    if (node.data.cells && Object.keys(node.data.cells).length > 1) {
      updatedRows.push(setLoadingCellsForColumnIds(node.data, columnIds, true));
    }
  });

  const result = gridApi.applyServerSideTransaction({
    update: updatedRows,
  });

  // Sanity check / observability
  if (!result?.update?.length) {
    logger.error(
      "Failed to apply client side transaction: update loading cells",
      { columnIds, result }
    );
  }
};

/**
 * @summary ⚡ Sets SSRM loading cells for specific rows + columns to the grid ⚡
 * @param gridApi The grid API
 * @param rowIds The row IDs to set loading cells for
 * @param columnIds The column IDs for the current matrixs
 * @param overwrite Whether to overwrite existing cells
 **/
export const ssrmSetLoadingCellsForRows = (
  gridApi: MatrixGridApi,
  rowIds: string[],
  columnIds: string[],
  overwrite = false,
  allRows = false
) => {
  // Update the client side grid with the new loading cells
  const updatedRows: SSRMRow[] = [];
  gridApi.forEachNode((node) => {
    if (!node.data?.id) return;

    // We only update the row if it has a summary column
    if (allRows || rowIds.includes(node.data.id)) {
      const rowWithLoadingCells = setLoadingCellsForColumnIds(
        node.data,
        columnIds,
        overwrite
      );

      // We also need to update the document status if we are overwriting so we can actually see the cells
      if (overwrite) {
        updatedRows.push(resetRowDocumentTextParseStatus(rowWithLoadingCells));
      } else {
        updatedRows.push(rowWithLoadingCells);
      }
    }
  });

  const result = gridApi.applyServerSideTransaction({
    update: updatedRows,
  });

  // Sanity check / observability
  if (!result?.update?.length) {
    logger.error(
      "Failed to apply client side transaction: update loading rows",
      { rowIds, columnIds, result }
    );
  }
};

/**
 * @summary ⚡ Creates SSRM temporary loading cells for docIds to the grid ⚡
 * @param gridApi The grid API
 * @param docIds The doc ids being added
 * @param columnIds The column IDs for the current matrix
 **/
export const ssrmCreateLoadingRowsForDocIds = (
  gridApi: MatrixGridApi,
  docIds: string[],
  columnIds: string[]
) => {
  const rowStubs = docIds.map((docId) => {
    const stub = {
      id: docId + "-loading",
      document: { id: docId },
      cells: {},
    };

    // @ts-ignore: Document stubs don't match out typescript types but thats ok
    return setLoadingCellsForColumnIds(stub, columnIds, true);
  });

  const result = gridApi?.applyServerSideTransaction({
    add: rowStubs,
  });

  // Sanity check / observability
  if (!result?.add?.length) {
    logger.error(
      "Failed to apply client side transaction: create rows for the grid via doc ids",
      { docIds, result }
    );
  }
};

/**
 * @summary ⚡ Deletes SSRM rows from the grid on the client ⚡
 * @param gridApi The grid API
 * @param docIds The doc IDs to remove
 **/
export const ssrmDeleteRowsByDocIds = (
  gridApi: MatrixGridApi,
  docIds: string[]
) => {
  const rowsToRemove: SSRMRow[] = [];
  gridApi.forEachNode((node) => {
    if (!node.data?.document?.id) return;

    // We only update the row if it has a summary column
    if (docIds.includes(node.data.document.id)) {
      rowsToRemove.push(node.data);
    }
  });

  const result = gridApi.applyServerSideTransaction({
    remove: rowsToRemove,
  });

  // Sanity check / observability
  if (!result?.remove?.length) {
    logger.error(
      "Failed to apply client side transaction: remove rows from the grid via doc ids",
      { docIds, result }
    );
  }
};

/**
 * @summary ⚡ Deletes SSRM rows from the grid on the client ⚡
 * @param gridApi The grid API
 * @param rowIds The row IDs to remove
 **/
export const ssrmDeleteRowsByRowIds = (
  gridApi: MatrixGridApi,
  rowIds: string[]
) => {
  const rowsToRemove: SSRMRow[] = [];
  gridApi.forEachNode((node) => {
    if (!node.data?.id) return;

    // We only update the row if it has a summary column
    if (rowIds.includes(node.data.id)) {
      rowsToRemove.push(node.data);
    }
  });

  const result = gridApi.applyServerSideTransaction({
    remove: rowsToRemove,
  });

  // Sanity check / observability
  if (!result?.remove?.length) {
    logger.error(
      "Failed to apply client side transaction: remove rows from the grid via row ids",
      { rowIds, result }
    );
  }
};

// ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡ ⚡
