import { getDefaultAgentMessage } from "source/components/matrix/matrix-chat/shared/helpers";
import { AppThunk } from "source/redux";
import {
  clearMatrixChatState,
  getMostRecentMatrixChatActions,
  setMatrixChatGridFilter,
  setMatrixChatMessages,
  getMatrixChatMessages,
  upsertMatrixChatMessage,
  setIsMatrixChatLoading,
  setUserGridFilter,
  getUserGridFilter,
} from "source/redux/matrixChat";
import { getDisplayReportToolMap } from "source/redux/matrix";
import { getName } from "source/redux/user";
import { MatrixGridApi } from "source/components/matrix/types/grid.types";
import {
  ApplyGridFilterProps,
  applyGridFilter,
} from "source/utils/matrix/grid/gridUtilLayer";

/**Thunk to handle setting initial matrix chat message */
export const setInitialMatrixChatMessage =
  (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const name = getName(state);

    dispatch(
      setMatrixChatMessages([
        getDefaultAgentMessage({
          name,
        }),
      ])
    );
  };

/**Thunk to handle clearing matrix chat state, using doc state and name */
export const handleClearMatrixChatState =
  (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const name = getName(state);
    dispatch(clearMatrixChatState({ name }));
  };

export const handleApplyMatrixChatGridFilter =
  (payload: ApplyGridFilterProps): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const toolMap = getDisplayReportToolMap(state);
    const currentGridFilter = payload.gridApi?.getFilterModel();
    dispatch(setUserGridFilter(currentGridFilter ?? null));
    const newFilterModel = applyGridFilter({ ...payload, toolMap });
    dispatch(setMatrixChatGridFilter(newFilterModel));
  };

export const handleClearMatrixChatGridFilter =
  (gridApi?: MatrixGridApi): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const MCGridFilter = getMostRecentMatrixChatActions(state).grid_filter;
    if (MCGridFilter) {
      // fallback to user-applied filter, if any
      const userGridFilter = getUserGridFilter(state);
      gridApi?.setFilterModel(userGridFilter);
      dispatch(setMatrixChatGridFilter(null));
    }
  };

export const setGenerateChartsErrorMessage =
  (message: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const messages = getMatrixChatMessages(state);
    const latestMessage = messages[messages.length - 1];
    if (!latestMessage) return;
    dispatch(
      upsertMatrixChatMessage({
        ...latestMessage,
        error: message,
        loading: false,
      })
    );
    dispatch(setIsMatrixChatLoading(false));
  };
