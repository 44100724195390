import React, { useMemo } from "react";
import { classNames } from "core";
import { ColumnViewConfiguration } from "source/components/matrix/types/reports.types";
import { generateBackgroundColor } from "../../helpers/display";

type SelectChipProps = {
  answer: string;
  viewConfig?: ColumnViewConfiguration;
  outputOptions: string[];
  className?: string;
};

export const SelectChip = ({
  answer,
  viewConfig,
  outputOptions,
  className,
}: SelectChipProps) => {
  const colorId = useMemo(() => {
    return viewConfig?.output_option_config?.find(
      (option) => option.name === answer
    )?.color;
  }, [viewConfig, answer]);

  return (
    <span
      className={classNames(
        "z-[1] h-5 flex-shrink-0 justify-start truncate rounded px-1.5 py-0.5 text-xs font-medium",
        className
      )}
      style={generateBackgroundColor(outputOptions.indexOf(answer), colorId)}
    >
      {answer}
    </span>
  );
};
