import { CustomFilterProps } from "ag-grid-react";
import { OutputFormat } from "source/components/matrix/types/reports.types";
import { classNames } from "core";
import React, { useEffect, useRef, useState } from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import { MaterialSymbolsIcon } from "core";
import { SelectChip } from "./SelectChip";
import { useSelector } from "react-redux";
import { getLocalViewConfig } from "source/redux/matrix";

export type CustomSelectFilterProps = {
  columnId: string;
  v2OutputFormat: OutputFormat;
  outputOptions: string[];
};

type Props = CustomFilterProps & CustomSelectFilterProps;

export const assembleFilter = (
  v2OutputFormat: string,
  selectedOptions: string[]
) => {
  if (v2OutputFormat === "select") {
    return {
      filterType: "text",
      type: "inListSelect",
      filterList: selectedOptions,
    };
  }
  return {
    filterType: "text",
    type: "inListMultiselect",
    filterList: selectedOptions,
  };
};

export const CustomSelectFilter = ({
  model,
  onModelChange,
  columnId,
  v2OutputFormat,
  outputOptions,
}: Props) => {
  const currViewConfig = useSelector(getLocalViewConfig) ?? undefined;
  const columnViewConfig =
    currViewConfig?.column_view_configuration?.[columnId ?? ""];

  const [selectedOptions, setSelectedOptions] =
    useState<string[]>(outputOptions);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const filterModelInitialized = useRef(false);

  const handleSelection = (item: string) => {
    let newSelectedOptions: string[] = [];
    if (selectedOptions?.includes(item)) {
      // remove item from selectedOptions
      newSelectedOptions = selectedOptions?.filter((v) => v !== item);
    } else {
      // add item to selectedOptions
      newSelectedOptions = [...(selectedOptions ?? []), item];
    }

    setSelectedOptions(newSelectedOptions);
    onModelChange(assembleFilter(v2OutputFormat, newSelectedOptions));
  };

  const handleOnlySelection = (item: string) => {
    setSelectedOptions([item]);
    onModelChange(assembleFilter(v2OutputFormat, [item]));
  };

  const filteredOptions = outputOptions.filter((option) =>
    option.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Add a tally to "things using grid state would fix"..
  // Hacky way to initialize the filter model with the selected options
  useEffect(() => {
    if (filterModelInitialized.current) return;
    if (model?.filterList) {
      filterModelInitialized.current = true;
      setSelectedOptions(model?.filterList);
    }
  }, [model]);

  return (
    <div className="bg-white">
      <div className="flex items-center border-b border-gray-200 px-2.5 py-1">
        <MaterialSymbolsIcon
          icon="search"
          size="lg"
          className="text-gray-400"
        />
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search..."
          className="w-[100%] !border-0 p-2 !outline-none"
        />
      </div>
      <div
        className={classNames(
          "force-show-scrollbar mt-2.5 box-border max-h-[300px] overflow-scroll bg-white px-3 pt-2 text-xs font-medium shadow-xl outline-none ring-0 focus:ring-0 focus-visible:ring-0"
        )}
      >
        {filteredOptions?.map((item, index) => (
          <div
            key={index}
            className="group mb-2.5 flex cursor-pointer overflow-scroll text-xs"
            onClick={() => {
              handleSelection(item);
            }}
            onDoubleClick={() => {
              handleOnlySelection(item);
            }}
          >
            <span
              className={classNames(
                "mr-2.5 mt-0.5 flex h-4 w-4 items-center justify-center rounded-sm text-white",
                selectedOptions?.includes(item)
                  ? "bg-hebbiaBlue"
                  : "border border-[#C8CCD5] text-transparent hover:border-hebbiaBlue group-hover:border-hebbiaBlue"
              )}
            >
              {selectedOptions?.includes(item) ? (
                <CheckRoundedIcon fontSize="inherit" />
              ) : (
                <CheckBoxOutlineBlankSharpIcon fontSize="inherit" />
              )}
            </span>
            <SelectChip
              key={index}
              answer={item}
              viewConfig={columnViewConfig}
              outputOptions={outputOptions}
            />
          </div>
        ))}
      </div>
      <div className="flex justify-end border-t border-gray-200 bg-white p-3">
        <button
          onClick={() => {
            setSelectedOptions(outputOptions);
            onModelChange(null);
            setSearchQuery(""); // Reset search query on reset
          }}
          className="rounded-sm border border-hebbiaBlue px-3 py-2 font-semibold text-hebbiaBlue"
        >
          Reset
        </button>
        <button
          onClick={() => {
            setSelectedOptions([]);
            onModelChange([]);
            setSearchQuery(""); // Reset search query on reset
            handleOnlySelection("");
          }}
          className="ml-2 rounded-sm border border-red-500 px-3 py-2 font-semibold text-red-500"
        >
          Clear
        </button>
      </div>
    </div>
  );
};
