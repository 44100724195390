import { v4 as uuidv4 } from "uuid";
import {
  isReportsTableResponse,
  logReportResponse,
} from "source/components/matrix/contexts/utils";
import {
  CachedCellsResponsePayload,
  ToolReadyPayload,
  LoadingCellsResponsePayload,
  ProgressUpdateResponsePayload,
  ReportWebsocketEvent,
  ToolCompleteResponsePayload,
  GatherCompleteResponsePayload,
} from "source/components/matrix/types/websocket.types";
import { AppThunk } from "source/redux";
import {
  getActiveReport,
  getActiveTabId,
  getFindToolColumnNames,
  getIsReportNameDefault,
  getSocketLeaseId,
  setLeaseId,
  setReportProgressUpdate,
  updateReportVersion,
} from "source/redux/matrix";
import logger from "source/utils/logger";
import {
  GenerateReportTitleCallback,
  MIN_AUTOTITLE_COLUMNS,
  MIN_AUTOTITLE_DOCUMENTS,
} from "source/hooks/matrix/useRenameReport";
import { MatrixGridApi } from "source/components/matrix/types/grid.types";
import { queryClient } from "pages/_app";
import { LogPosthogCallback } from "../../hooks/tracking/usePosthogTracking";
import { getRowByIdQueryKey } from "source/hooks/matrix/useQueryRowById";
import { setMatrixCellLoadingState } from "source/utils/matrix/grid/gridUtilLayer";
import { CellLoadingStep } from "source/components/matrix/types/cells.types";
import { ssrmBulkApplyCells } from "source/utils/matrix/ltl/lightningTransactionLayer";
import { RefreshServerSideParams } from "ag-grid-community";

export const initializeLeaseId =
  (): AppThunk<string> => (dispatch, getState) => {
    const state = getState();
    const leaseId = getSocketLeaseId(state);
    const newLeaseId = leaseId ?? uuidv4();

    dispatch(setLeaseId(newLeaseId));
    return newLeaseId;
  };

export const handleProgressUpdate =
  (res: ProgressUpdateResponsePayload): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const activeReport = getActiveReport(state);

    // Sanity check
    if (activeReport?.id !== res.report_id) return;

    dispatch(setReportProgressUpdate(res));
  };

/**
 * This comes up when we've already run a column for a given tool param + doc
 * and will essentially return earlier than tool_ready right after loading cells
 */
export const handleCachedCellsEvent =
  (res: CachedCellsResponsePayload, gridApi?: MatrixGridApi): AppThunk =>
  (dispatch, getState) => {
    const reportId = res.report_id;
    const requestId = res.request_id;

    const state = getState();
    const tabId = getActiveTabId(state);

    // Sanity check
    if (!tabId) return;

    logReportResponse({
      event: ReportWebsocketEvent.CACHED_CELLS,
      reportId,
      requestId,
    });

    if (!gridApi) {
      logger.error(
        "Grid API not instantiated before running handleCachedCellsEvent"
      );
      return;
    }

    // Apply client side cells
    ssrmBulkApplyCells(gridApi, res.cells);
  };

/**
 * This is essentially an ACK from the backend that these are the cells that are actually in a loading state
 */
export const handleLoadingCellsEvent =
  (res: LoadingCellsResponsePayload, gridApi?: MatrixGridApi): AppThunk =>
  (dispatch, getState) => {
    const reportId = res.report_id;
    const requestId = res.request_id;

    const state = getState();
    const tabId = getActiveTabId(state);

    // Sanity check
    if (!tabId) return;

    logReportResponse({
      event: ReportWebsocketEvent.LOADING_CELLS,
      reportId,
      requestId,
    });

    // Sanity check
    if (!gridApi) {
      logger.error(
        "Grid API not instantiated before running handleLoadingCellsEvent"
      );
      return;
    }

    // Apply client side loading cells
    ssrmBulkApplyCells(gridApi, res.cells);
  };

// TODO: @eash will probably need to bring back some of this commented code at some point
export const handleToolReadyEvent =
  (
    res: ToolReadyPayload,
    gridApi?: MatrixGridApi,
    logPosthog?: LogPosthogCallback
  ): AppThunk =>
  (dispatch, getState) => {
    if (!gridApi) {
      logger.error(
        "Grid API not instantiated before running handleToolReadyEvent"
      );
      return;
    }

    logReportResponse({
      event: ReportWebsocketEvent.TOOL_READY,
      reportId: res.report_id,
      requestId: res.request_id,
    });

    const { cells } = res;

    const resCellRowIds = cells.map((cell) => cell.row_id ?? "");
    if (!resCellRowIds) {
      return;
    }

    // Apply client side cells
    ssrmBulkApplyCells(gridApi, res.cells);

    // Invalidate the doc viewer row query for every cell that was updated
    resCellRowIds.forEach((rowId) => {
      queryClient.invalidateQueries({
        queryKey: getRowByIdQueryKey(rowId),
      });
    });

    // TODO: @eash - need to make this logic more efficient by checking the cell row id / y and comparing it to which nodes are currently visible
    // Also would be good to only refresh the nodes that are visible

    // logCellUpdatesToPosthog({
    //   logPosthog,
    //   cellUpdates: cellsToUpsert,
    //   gridApi,
    // });

    // checkAndAlertForErrorCells(resReportId, res.tab_id, resCells, user);
  };

export const handleToolCompleteEvent =
  (
    res: ToolCompleteResponsePayload,
    gridApi: MatrixGridApi | undefined,
    generateReportTitle: GenerateReportTitleCallback,
    refreshServerSide?: (params?: RefreshServerSideParams) => void
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const activeReport = getActiveReport(state);
    const toolColumnNames = getFindToolColumnNames(state);
    const isMatrixNameDefault = getIsReportNameDefault(state);

    logReportResponse({
      event: ReportWebsocketEvent.TOOL_COMPLETE,
      reportId: res.report_id,
      requestId: res.request_id,
    });

    // Sanity check
    if (
      !activeReport?.id ||
      !isReportsTableResponse(res.report_id, activeReport?.id)
    ) {
      return;
    }

    const { version_id: resVersionId, created_at: resCreatedAt } = res;

    // Update the report version if the incoming version is newer than what is known
    // in redux
    if (
      !activeReport.created_at ||
      new Date(resCreatedAt) > new Date(activeReport.created_at)
    ) {
      dispatch(
        updateReportVersion({
          createdAt: resCreatedAt,
          versionId: resVersionId,
        })
      );
    }

    // If we add a row or col check to see if we should rename the matrix
    // TODO: Move this out of the Websocket handler and into it's own hook
    if (
      isMatrixNameDefault &&
      (res.tool === "find" || res.tool === "retrieve")
    ) {
      // Ensure the user has added a column before generating a report title
      const displayedRowCount = gridApi?.getDisplayedRowCount() ?? 0;

      // Ensure the user has added enough documents before generating a report title
      if (
        toolColumnNames &&
        toolColumnNames.length >= MIN_AUTOTITLE_COLUMNS &&
        displayedRowCount >= MIN_AUTOTITLE_DOCUMENTS
      ) {
        // TODO: Move this to the backend and let it decide how it wants to be named
        const documentNames: string[] = [];
        gridApi?.forEachNode((node) => {
          const documentName = node.data?.document?.title;
          if (documentName) {
            documentNames.push(documentName);
          }
        });

        if (documentNames.length > 0) {
          generateReportTitle(
            activeReport?.id,
            activeReport?.name,
            documentNames,
            toolColumnNames
          );
        }
      }
    }

    // Refresh the rows if the retrieve tool is complete.
    // We have to do this currently because when adding documents, we don't immediately know the new row ids and can't optimistically update the rows.
    // TODO: Update add/set docs partials to return new row ids so we can optimistically update the rows and remove this call
    if (res.tool === "retrieve" && refreshServerSide) {
      refreshServerSide();
    }
  };

export const handleGatherCompleteEvent =
  (res: GatherCompleteResponsePayload, gridApi?: MatrixGridApi): AppThunk =>
  (_dispatch, _getState) => {
    const rowId = res.row_id;
    const cellId = res.cell_id;
    const columnId = res.static_column_id;

    logReportResponse({
      event: ReportWebsocketEvent.GATHER_COMPLETE,
      reportId: res.report_id,
      requestId: res.request_id,
    });

    // Sanity check
    if (!gridApi || !cellId) return;

    // Set loading state to synthesizing answer for this cell
    setMatrixCellLoadingState(
      gridApi,
      cellId,
      CellLoadingStep.SYNTHESIZING_ANSWER
    );

    const rowNode = gridApi.getRowNode(rowId);

    // Refresh just this cell
    if (rowNode && columnId) {
      gridApi.refreshCells({
        rowNodes: [rowNode],
        columns: [columnId],
        force: true,
      });
    }
  };
