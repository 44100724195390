import {
  CellContent,
  EvidenceQuoteV2,
  FindResultType,
  SSRMRow,
} from "source/components/matrix/types/cells.types";
import {
  AdvancedFilterModel,
  CellPosition,
  CellRange,
  FilterModel,
  IsExternalFilterPresentParams,
  IsFullWidthRowParams,
  ProcessCellForExportParams,
  ValueGetterParams,
} from "ag-grid-community";
import {
  CellCache,
  MatrixDocumentTitleCellValue,
  MatrixGridApi,
  MatrixGridContext,
  MatrixGridDataType,
  MatrixIRowNode,
} from "source/components/matrix/types/grid.types";
import {
  generateOptimisticLoadingCellId,
  generatePaddingRows,
  isAnswerCell,
  isPaddingRow,
} from "source/utils/matrix/cells";
import { isAnswerToolType } from "source/utils/matrix/tools";
import { KEY_DOWN, KEY_LEFT, KEY_RIGHT, KEY_UP } from "source/constants";
import { isEmpty } from "lodash";
import logger from "source/utils/logger";
import { TIMESTAMP_COL_ID } from "../config";
import { formatDateString, TimeFormat } from "source/utils/common/time";
import {
  getMatrixContextValue,
  setMatrixContextValue,
} from "source/utils/matrix/grid/gridUtilLayer";

export const processCellForReportClipboard = (
  params: ProcessCellForExportParams<MatrixGridDataType, MatrixGridContext>
) => {
  // Parse out diff types of cells, order of parsing MATTERS since dates, repos are strings
  // If there is no value and the column is a group synthesis column, we need to get the answer from the group synthesis
  if (!params.value && params.node?.group) {
    return "";
  }

  // If is find tool result, let us try to parse it as such
  if (isAnswerToolType(params.value?.tool)) {
    const answers = params.value.result.answers;
    if (answers?.length) {
      const answer = answers[0];
      if (answer?.answer) {
        return answer.answer;
      }
    }
  }

  // See if this is a date
  const columnId = params.column.getColId();
  if (columnId === TIMESTAMP_COL_ID) {
    const date = formatDateString(params.value, TimeFormat.CALENDAR_DATE);
    if (date !== "Invalid date") {
      return date;
    }
  }

  // If string, return it straight up
  if (
    typeof params.value === "string" ||
    typeof params.value === "number" ||
    typeof params.value === "boolean"
  )
    return params.value;

  return "";
};

// Will return original string without leading and trailing spaces and newline characters.
export const cleanAnswerString = (answer?: string | null) =>
  (answer ?? "").replace(/^\s+|\s+$/g, "");

export const getAnswerByIndex = (
  result?: FindResultType,
  index?: number,
  isValidSingleSelectOption?: boolean
) => {
  if (!result || index === undefined) return;
  const rawAnswer = result.answers?.[index]?.answer?.trim();
  const answerArr = result.answers?.[index]?.answer_arr;
  if (answerArr) {
    if (answerArr.length > 0) {
      return answerArr.join(", ");
    } else {
      return "—";
    }
  }
  return !isValidSingleSelectOption && getIsAnswerNotFound(rawAnswer)
    ? "—"
    : rawAnswer;
};

export const getEvidenceQuotesByIndex = (
  result?: FindResultType,
  index?: number
): string[] | EvidenceQuoteV2[] => {
  if (!result || index === undefined) return [];

  // Check if we have evidence quotes v2, this will be completely overhauled shortly
  const evidenceQuotesV2 = result.answers?.[index]?.evidence_quotes_v2 ?? [];
  const showV2Quotes =
    evidenceQuotesV2.length &&
    !evidenceQuotesV2.find(
      (quote) => quote.kind === "text_excerpt" && quote.is_validated === false
    );
  if (showV2Quotes) {
    return evidenceQuotesV2.filter((quote) => {
      if (quote.kind === "table") {
        return true;
      }

      if (quote.kind === "text_excerpt") {
        return quote.can_be_highlighted && quote.is_validated;
      }

      if (quote.kind === "excel") {
        return true;
      }

      return false;
    });
  }

  // Check if we have evidence quotes
  const evidenceQuotes = result.answers?.[index]?.evidence_quotes?.filter(
    (x) => !getIsAnswerNotFound(x)
  );
  if (evidenceQuotes && evidenceQuotes.length > 0) {
    return evidenceQuotes;
  }

  // If not, check if we have a single evidence quote
  const evidenceQuote = result.answers?.[index]?.evidence_quote;
  if (evidenceQuote && !getIsAnswerNotFound(evidenceQuote)) {
    return [evidenceQuote];
  }

  // If not, return empty list
  return [];
};

/**
 * Helper for cell value getters
 * Returns null for loading state, undefined for other errors
 * Null vs undefined distinction is important for cell rerenders
 */
export const getCellValue = <T>(
  params: ValueGetterParams<MatrixGridDataType, T>,
  parser: (value: string) => T | undefined
): T | undefined | null => {
  const columnId = params.column.getColId();
  if (columnId === undefined) return;
  const cell = params.data?.cells?.[columnId];
  const doc = params.data?.document;
  const docStatus = doc?.text_parse_status;
  if (
    docStatus &&
    ["FAILED", "RUNNING_OCR", "BUILDING", "PENDING"].includes(docStatus)
  )
    return docStatus as T;

  if (cell?.loading_step_message) return cell.loading_step_message as T;
  if (cell?.error) return "ERROR" as T;
  if (cell?.loading) return "LOADING" as T;
  if (cell?.tool === "padding") return undefined;

  if (cell && !isAnswerCell(cell)) return undefined;
  const answer_arr = cell?.result?.answers?.[0]?.answer_arr;

  if (answer_arr && answer_arr.length > 0) {
    return answer_arr.join(", ") as T;
  }

  const value = cell?.result?.answers?.[0]?.answer;
  if (typeof value !== "string") return value;

  if (getIsAnswerNotFound(value)) return undefined;

  try {
    return parser(value);
  } catch (e) {
    return undefined;
  }
};

export const getCellValueAsString = (
  params: ValueGetterParams<MatrixGridDataType, string>
) => getCellValue(params, cleanAnswerString);

export const getCellValueAsDate = (
  params: ValueGetterParams<MatrixGridDataType, Date>
) =>
  getCellValue(params, (value) => {
    const timestamp = Date.parse(value);
    return isNaN(timestamp) ? undefined : new Date(timestamp);
  });

export const getCellValueAsFloat = (
  params: ValueGetterParams<MatrixGridDataType, number>
) =>
  getCellValue(params, (value) => {
    const cleanValue = value.replaceAll(",", "");
    const numberValue = parseFloat(cleanValue);
    return isNaN(numberValue) ? undefined : numberValue;
  });

export const getIsAnswerNotFound = (answer?: string) => {
  if (!answer) return false;
  const cleanAnswer = cleanAnswerString(answer).toLowerCase();
  const notFoundStrings = new Set([
    "not found",
    "na",
    "n/a",
    "-na",
    "?not found?",
    "-",
    "—",
    " ",
    "",
  ]);
  return notFoundStrings.has(cleanAnswer);
};

export const getIsRangeSelected = (cellRanges: CellRange[] | null) => {
  const cellRange = cellRanges?.[0];
  return !(
    cellRange?.startRow?.rowIndex === cellRange?.endRow?.rowIndex &&
    (cellRange?.columns.length ?? 0) < 2
  );
};

export const isFullWidthRow = ({
  rowNode,
  context: { totalDocumentRowCount, totalPaddingRowCount },
}: IsFullWidthRowParams<MatrixGridDataType, MatrixGridContext>) => {
  if (rowNode.failedLoad) {
    return true;
  }

  if (rowNode.data?.id === "reset-filters-row") {
    return true;
  }

  if (
    totalDocumentRowCount === undefined ||
    totalPaddingRowCount === undefined ||
    rowNode.rowIndex === null
  ) {
    return false;
  }

  // We have to explictly check for total real rows + total padding rows - 1
  return (
    isPaddingRow(rowNode.data) &&
    rowNode.rowIndex >= totalDocumentRowCount + totalPaddingRowCount - 1
  );
};

export const getDocumentCellValue = ({
  data,
}: ValueGetterParams<MatrixGridDataType, MatrixDocumentTitleCellValue>) => {
  if (!data) return null;
  const doc = data.document;
  if (!doc) return null;
  return {
    title: doc.title,
    docURL: doc.user_defined_display_data?.source_document_url,
    docStatus: doc?.text_parse_status,
    docFailureReason: doc?.text_parse_failure_reason,
    detect_tables_and_charts_status: doc?.detect_tables_and_charts_status,
  };
};

export const getDocumentDateCellValue = ({
  data,
}: ValueGetterParams<MatrixGridDataType, Date>) => {
  const value = data?.document?.timestamp;
  if (!value) return null;
  try {
    const date = new Date(Date.parse(value));
    return date;
  } catch (e) {
    return null;
  }
};

export const isRowSelectable = (node: MatrixIRowNode) => {
  return !isPaddingRow(node.data);
};

export const isExternalFilterPresent = ({
  api,
}: IsExternalFilterPresentParams<MatrixGridDataType>) => {
  return api.getRowGroupColumns()?.length > 0;
};

export const doesExternalFilterPass = (node: MatrixIRowNode) => {
  return !isPaddingRow(node.data);
};

export const onCellKeyDown = (
  selectedCell: CellPosition | null,
  keyPressed: string,
  api: MatrixGridApi
) => {
  const moveAndEditCell = (moveFunction: () => void) => {
    api.stopEditing();
    moveFunction();

    const nextSelectedCell = api.getFocusedCell();
    setTimeout(() => {
      // timeout needed to give the grid a chance to scroll to the newly focused cell
      api.startEditingCell({
        rowIndex: nextSelectedCell?.rowIndex ?? 0,
        colKey: nextSelectedCell?.column.getId() ?? "",
      });
    }, 200);
  };

  const cellBelowRowIndex = (selectedCell?.rowIndex ?? 0) + 1;
  const cellAboveRowIndex = (selectedCell?.rowIndex ?? 0) - 1;
  const selectCellColumnId = selectedCell?.column.getId() ?? "";

  switch (keyPressed) {
    case KEY_RIGHT:
      moveAndEditCell(() => api.tabToNextCell());
      break;
    case KEY_LEFT:
      moveAndEditCell(() => api.tabToPreviousCell());
      break;
    case KEY_DOWN:
      if (cellBelowRowIndex >= api.getDisplayedRowCount()) {
        return;
      }
      moveAndEditCell(() => {
        api.clearRangeSelection();
        api.setFocusedCell(cellBelowRowIndex, selectCellColumnId);
        api.addCellRange({
          rowStartIndex: cellBelowRowIndex,
          rowEndIndex: cellBelowRowIndex,
          columns: [selectCellColumnId],
        });
      });
      break;
    case KEY_UP:
      if (cellAboveRowIndex < 0) {
        return;
      }
      moveAndEditCell(() => {
        api.clearRangeSelection();
        api.setFocusedCell(cellAboveRowIndex, selectCellColumnId);
        api.addCellRange({
          rowStartIndex: cellAboveRowIndex,
          rowEndIndex: cellAboveRowIndex,
          columns: [selectCellColumnId],
        });
      });
      break;
  }
};

// SSRM related helpers
export const findCellByCellId = (
  gridApi: MatrixGridApi | undefined,
  cellId: string | undefined
): CellContent | undefined => {
  if (!gridApi || !cellId) {
    throw new Error("Grid API or cell ID is undefined");
  }

  const allRows = gridApi.getRenderedNodes();
  const allRowsCells = allRows?.map((node) => node.data);

  if (!allRowsCells) return;

  for (const row of allRowsCells) {
    if (row && row.cells) {
      const foundCell = Object.values(row.cells).find(
        (cell) => cell.id === cellId
      );
      if (foundCell) return foundCell;
    }
  }

  return undefined;
};

export const getFilterAndSortModel = (gridApi: MatrixGridApi | undefined) => {
  if (!gridApi) {
    return { filterModel: {}, sortModel: [] };
  }

  const columns = gridApi.getColumnState();
  const aggridFilterModel = gridApi.getFilterModel();
  const filterModel = migrateFilterModelData(gridApi, aggridFilterModel);
  const cleanFilterModel = cleanDateFilterModelKeys(gridApi, filterModel);

  const sortModel: Record<string, string>[] = [];

  columns?.forEach((column) => {
    const { colId, sort } = column;
    if (sort) {
      sortModel.push({
        colId,
        sort: sort.toUpperCase(),
      });
    }
  });

  return { filterModel: cleanFilterModel, sortModel };
};

export const doesAnyRowInAGGridCacheContainTheseRowIds = (
  gridApi: MatrixGridApi,
  rowIds: string[]
): boolean => {
  if (!gridApi || !rowIds || rowIds.length === 0) return false;

  return rowIds.some((rowId) => {
    const rowNode = gridApi?.getRowNode(rowId);
    return !!rowNode;
  });
};

export const applyCellOverridesToRows = (
  gridApi: MatrixGridApi,
  rows: SSRMRow[],
  findToolColumnIds: string[]
): SSRMRow[] => {
  const cellCache = {
    ...getMatrixContextValue<CellCache>(gridApi, "cellCache"),
  };

  // Convert the new column ids into loading cell stubs
  const loadingCellsStub = findToolColumnIds.reduce<Record<string, any>>(
    (acc, colId) => {
      return {
        ...acc,
        [colId]: {
          id: generateOptimisticLoadingCellId(),
          loading: true,
        },
      };
    },
    {}
  );

  // Apply any cached/loading cell overrides
  const mappedRows = rows.map((row) => {
    let cells = { ...row.cells };
    const cacheEntry = cellCache[row.id];

    // Apply the cache entry to the row
    if (cacheEntry) {
      cells = {
        ...cells,
        ...cacheEntry,
      };

      delete cellCache[row.id];
    }

    // If the row is running (i.e. has more than just a retrieve cell), apply loading cell stubs
    if (Object.keys(cells).length >= 2) {
      cells = {
        ...loadingCellsStub,
        ...cells,
      };
    }

    return {
      ...row,
      cells: cells,
    };
  });

  // Save the new cache state without the applied overrides
  setMatrixContextValue(gridApi, "cellCache", cellCache);

  return mappedRows;
};

export const getRowDataWithPaddingRows = (
  rows: SSRMRow[],
  totalRowCount: number | undefined,
  filterModel: FilterModel | AdvancedFilterModel | null,
  fullMatrixSearch: string | undefined,
  groupKeys: string[] | undefined
) => {
  const output = [...rows];

  // If there are no filter/search results, generate a placeholder row for grid reset
  if (output.length === 0 && (!isEmpty(filterModel) || !!fullMatrixSearch)) {
    return {
      rows: [{ id: "reset-filters-row", cells: {} }],
      paddingRowCount: 1,
    };
  }

  // Don't add padding rows for filtered grids
  if (!isEmpty(filterModel) || !!fullMatrixSearch || !!groupKeys?.length) {
    return { rows: output, paddingRowCount: 0 };
  }

  if (output.length === 0 && totalRowCount === 0) {
    return { rows: generatePaddingRows({ numRows: 6 }), paddingRowCount: 6 };
  }

  return { rows: output, paddingRowCount: 0 };
};

export const cleanDateFilterModelKeys = (
  gridApi: MatrixGridApi,
  filterModel: FilterModel | AdvancedFilterModel | null | undefined
) => {
  if (!filterModel || Object.keys(filterModel).length === 0) {
    return {};
  }

  return Object.keys(filterModel).reduce((acc, key) => {
    const filter = filterModel[key];
    const { dateFrom, dateTo, ...rest } = filter;

    acc[key] =
      dateFrom || dateTo
        ? { filter: dateFrom, filterTo: dateTo, ...rest }
        : rest;

    return acc;
  }, {} as FilterModel);
};

export const migrateFilterModelData = (
  gridApi: MatrixGridApi,
  filterModel: FilterModel | AdvancedFilterModel | null | undefined
) => {
  if (!filterModel) return filterModel;

  // Migrate any array models to full filter models
  Object.entries(filterModel).forEach(([colId, value]) => {
    if (Array.isArray(value)) {
      const colDef = gridApi.getColumnDef(colId);

      if (!colDef) {
        logger.error("Column definition not found for column id", { colId });
        delete filterModel[colId];
        return;
      }

      const outputFormat =
        colDef.context?.tool?.tool_params?.tool_spec?.output_format;

      filterModel[colId] = {
        filterList: value,
        filterType: "text",
        type:
          outputFormat === "multiselect" ? "inListMultiselect" : "inListSelect",
      };
    }
  });

  return filterModel;
};

export const scrollToAndHighlightCell = (
  gridApi: MatrixGridApi,
  cellId: string,
  rowIndex: number
) => {
  gridApi.ensureIndexVisible(rowIndex, "middle");

  const isRowCurrentlyVisible =
    !!gridApi.getDisplayedRowAtIndex(rowIndex)?.data;

  setTimeout(
    () => {
      const row = gridApi.getDisplayedRowAtIndex(rowIndex);

      if (!row?.data?.cells) {
        logger.error("Row/cell data not found for index", {
          rowIndex,
        });
        return;
      }

      const cellResult = Object.entries(row.data.cells).find(([, cell]) => {
        return cell.id === cellId;
      });

      // Flash cell and focus it
      if (cellResult && cellResult[0]) {
        const columnId = cellResult[0];
        gridApi.ensureColumnVisible(columnId, "middle");
        gridApi.setFocusedCell(rowIndex, columnId);
        gridApi.clearCellSelection();
        gridApi.addCellRange({
          rowStartIndex: rowIndex,
          rowEndIndex: rowIndex,
          columnStart: columnId,
          columnEnd: columnId,
        });
        gridApi.flashCells({
          rowNodes: [row],
          columns: [columnId],
        });
      }
    },
    isRowCurrentlyVisible ? 250 : 1250 // Wait for rows to load if not already visible
  );
};

/**
 * @summary convert all dates to string with YYYY-MM-DD 00:00:00 format
 */
export const cleanGroupKeys = (groupKeys: string[] | undefined) => {
  if (!groupKeys) return [];

  return groupKeys
    .map((key: any) => {
      if (key instanceof Date) {
        return key.toISOString().split("T")[0] + " 00:00:00";
      }
      return key;
    })
    .filter((key): key is string => key !== undefined);
};
