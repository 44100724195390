import React, { createContext, useCallback, useRef, useState } from "react";
import { MatrixGridApi } from "../types/grid.types";
import { RefreshServerSideParams } from "ag-grid-community";
import { getSSRMAllLoadedGroupRoutes } from "source/utils/matrix/grid/gridUtilLayer";
import { cleanGroupKeys } from "../tables/helpers/helpers";

type ReportsGridContextValue = {
  gridApi?: MatrixGridApi;
  // abortControllers: React.MutableRefObject<
  //   Record<string, AbortController | null>
  // >;
  setGridApi: (api?: MatrixGridApi) => void;
  refreshServerSide: (params?: RefreshServerSideParams) => void;
};

export const ReportsGridContext = createContext<ReportsGridContextValue>(
  {} as ReportsGridContextValue
);

type Props = { children: React.ReactNode };

const ReportsGridContextProvider = ({ children }: Props) => {
  // Track request abort controllers to prevent stale overwrites
  // const abortControllersRef = useRef<Record<string, AbortController | null>>({});

  const [gridApi, setGridApi] = useState<MatrixGridApi>();

  // const abortPreviousRequest = useCallback((route?: string) => {
  //   const routeKey = route ?? "root";

  //   // Cancel any in-progress requests
  //   if (abortControllersRef.current[routeKey]) {
  //     abortControllersRef.current[routeKey]?.abort();
  //     abortControllersRef.current[routeKey] = null;
  //   }

  //   //  Create a new AbortController and refresh the grid
  //   const controller = new AbortController();
  //   abortControllersRef.current[routeKey] = controller;
  // }, []);

  /**
   * @summary Refresh a server-side store level. If you pass no parameters, then the top level store is refreshed.
   * To refresh a child level, pass in the string of keys to get to the desired level. Once the store refresh is complete, the storeRefreshed event is fired.
   */
  const refreshServerSide = useCallback(
    (params?: RefreshServerSideParams) => {
      // Sanity check
      if (!gridApi) return;

      // Refresh server side at the given route
      if (params?.route?.length) {
        // const cleanedRoute = cleanGroupKeys(params.route);
        // abortPreviousRequest(cleanedRoute.join("/"));
        gridApi.refreshServerSide(params);
        return;
      }

      const rowGroups = gridApi.getRowGroupColumns();

      // If there are row groups, we need to refresh each group individually
      if (rowGroups && rowGroups.length > 0) {
        const routes = getSSRMAllLoadedGroupRoutes(gridApi);
        routes.forEach((route) => {
          if (!route) return;
          refreshServerSide({ ...params, route: [route] });
        });
      }

      // Finally, refresh the top level of the grid
      // abortPreviousRequest();
      gridApi.refreshServerSide(params);
    },
    [gridApi]
  );

  return (
    <ReportsGridContext.Provider
      value={{
        gridApi,
        // abortControllers: abortControllersRef,
        setGridApi,
        refreshServerSide,
      }}
    >
      {children}
    </ReportsGridContext.Provider>
  );
};

export default ReportsGridContextProvider;
