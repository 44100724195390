import {
  CellClassParams,
  IRowNode,
  RowClassParams,
  RowHeightParams,
} from "ag-grid-community";
import { CellOverflowMethod, ViewConfig } from "../../types/reports.types";
import {
  ONE_LINE_ROW_HEIGHT,
  FOUR_LINE_ROW_HEIGHT,
  TWO_LINE_ROW_HEIGHT,
  DEFAULT_CELL_OVERFLOW_METHOD,
} from "../config";
import {
  MatrixGridContext,
  MatrixGridDataType,
} from "source/components/matrix/types/grid.types";
import {
  BACKGROUND_COLORS,
  COLOR_MAP,
  TEXT_COLORS,
} from "source/components/matrix/constants";
import { isFullWidthRow } from "./helpers";

export const getCellIsCondensed = (
  node: IRowNode | null,
  cellOverflowMethod: CellOverflowMethod
) =>
  (node?.rowHeight || 0) < 50 ||
  cellOverflowMethod === CellOverflowMethod.ONE_LINE;

export const getDisableCellHover = (
  node: IRowNode | null,
  value: any,
  isLoading: boolean
) => !!node?.group || !value || isLoading;

export const getLineClamp = ({
  cellOverflowMethod = DEFAULT_CELL_OVERFLOW_METHOD,
  rowPinned,
  isReportLoading,
}: {
  cellOverflowMethod?: CellOverflowMethod;
  rowPinned?: boolean;
  isReportLoading?: boolean;
}) => {
  if (cellOverflowMethod === CellOverflowMethod.ONE_LINE) return "line-clamp-1";
  if (cellOverflowMethod === CellOverflowMethod.TWO_LINES)
    return "line-clamp-2";
  else if (
    cellOverflowMethod === CellOverflowMethod.FOUR_LINES ||
    rowPinned ||
    (isReportLoading && cellOverflowMethod === CellOverflowMethod.WRAP_TEXT)
  )
    return "line-clamp-4";
  return "line-clamp-none";
};

export const getRowHeight = ({
  rowIndex,
  cellOverflowMethod = DEFAULT_CELL_OVERFLOW_METHOD,
  rowPinned,
  isReportLoading,
  isLastRow,
  viewConfig,
}: {
  rowIndex?: number | null;
  cellOverflowMethod?: CellOverflowMethod;
  rowPinned?: boolean;
  isReportLoading?: boolean;
  isLastRow?: boolean;
  viewConfig?: ViewConfig | null;
}) => {
  if (isLastRow) return ONE_LINE_ROW_HEIGHT;
  if (
    rowIndex !== null &&
    rowIndex !== undefined &&
    cellOverflowMethod === CellOverflowMethod.CUSTOM
  ) {
    return (
      viewConfig?.row_configuration?.[rowIndex]?.height ?? FOUR_LINE_ROW_HEIGHT
    );
  } else if (
    isReportLoading &&
    cellOverflowMethod === CellOverflowMethod.WRAP_TEXT
  ) {
    // While loading in wrapped mode, we show the preserved row height or fallback to 4 lines
    if (rowIndex === null || rowIndex === undefined) {
      return FOUR_LINE_ROW_HEIGHT;
    }

    return (
      viewConfig?.row_configuration?.[rowIndex]?.height ?? FOUR_LINE_ROW_HEIGHT
    );
  } else if (cellOverflowMethod === CellOverflowMethod.ONE_LINE)
    return ONE_LINE_ROW_HEIGHT;
  else if (cellOverflowMethod === CellOverflowMethod.TWO_LINES)
    return TWO_LINE_ROW_HEIGHT;
  else if (cellOverflowMethod === CellOverflowMethod.FOUR_LINES || rowPinned)
    return FOUR_LINE_ROW_HEIGHT;
  return undefined;
};

export const getAgGridRowHeight = ({
  api,
  node,
  context,
}: RowHeightParams<MatrixGridDataType, MatrixGridContext>) => {
  const { viewConfig, isReportLoading, cellOverflowMethod } = context;
  const height = getRowHeight({
    rowIndex: node.rowIndex,
    cellOverflowMethod,
    rowPinned: node.isRowPinned(),
    isReportLoading,
    viewConfig: viewConfig,
    isLastRow: isFullWidthRow({ api, context, rowNode: node }),
  });
  return height;
};

/**
 * @returns Actual display row index. AGGrid gives a rowIndex to grouped rows, and restarts index after pinned rows.
 * To avoid that, need to account for if the row is pinned and if the row is in a group.
 */
export const getDisplayRowIndex = (
  isPinned: boolean,
  node: IRowNode,
  numPinnedRows: number
) => {
  if (node.rowIndex == null) return 0;
  // Get the number of group rows before current node. +1 since it's 0-indexed
  // const numPrevGroupedRows =
  //   node.parent?.childIndex != null ? node.parent?.childIndex + 1 : 0;
  // If row isn't pinned, add the number of pinned rows. Otherwise, keep row index.
  const rowIndex = node.rowIndex + (!isPinned ? numPinnedRows : 0);
  return rowIndex;
};

export const getAgGridDefaultCellStyle = ({
  rowIndex,
  context,
}: CellClassParams<MatrixGridDataType, number | string | boolean | Date>) => {
  const { viewConfig, cellOverflowMethod, isReportLoading } =
    context as MatrixGridContext;

  const height = getRowHeight({
    rowIndex,
    isReportLoading,
    viewConfig: viewConfig,
    cellOverflowMethod: cellOverflowMethod,
  });
  return {
    ...(height ? { height: height - 1 } : {}),
  };
};

export const getAgGridRowStyle = ({
  node,
}: RowClassParams<MatrixGridDataType>) => {
  const isRowGroup = node.group && node.key;
  if (isRowGroup) {
    return {
      background: "var(--ag-header-background-color)",
    };
  } else {
    return {
      background: "white",
    };
  }
};

export const generateBackgroundColor = (index: number, colorId?: string) => {
  return {
    color: colorId ? getChipColorById(colorId) : getDefaultChipColor(index),
    backgroundColor: colorId
      ? getChipBackgroundColorById(colorId)
      : getDefaultChipBackgroundColor(index),
  };
};

export const getChipColorById = (id: string) => {
  return COLOR_MAP[id]?.text;
};

export const getChipBackgroundColorById = (id: string) => {
  return COLOR_MAP[id]?.background;
};

export const getDefaultChipColor = (index: number) => {
  return TEXT_COLORS[index % TEXT_COLORS.length];
};

export const getDefaultChipBackgroundColor = (index: number) => {
  return BACKGROUND_COLORS[index % BACKGROUND_COLORS.length];
};
