import { v4 as uuidv4 } from "uuid";
import {
  isReportsTableResponse,
  logReportResponse,
} from "source/components/matrix/contexts/utils";
import {
  MCAskToolIncomingResponsePayload,
  MCCitationInfoResponsePayload,
  MCColumnConversionPayload,
  MCFastBuildDocIDsPayload,
  MCGetToolIncomingResponsePayload,
  MCLoadHistoryPayload,
  MCMessageResponsePayload,
  MatrixChatWebsocketEvent,
} from "source/components/matrix/types/websocket.types";
import { AppThunk } from "source/redux";
import {
  getActiveReport,
  getDisplayReportToolMap,
  upsertReportTool,
} from "source/redux/matrix";
import logger from "source/utils/logger";
import {
  MatrixColConversionRequest,
  addCitationsFromMessage,
  addMatrixChatMessages,
  setCitationIdMap,
  setColumnTypeConversionRequests,
  setIsHistoryLoaded,
  setIsMatrixChatLoading,
  setMostRecentMatrixChatActions,
  setTokenLimitError,
  upsertMatrixChatMessage,
} from "source/redux/matrixChat";
import { upsertToast } from "source/redux/ui";
import { delay } from "lodash";
import { removeSpacesBeforePunctuation } from "source/utils/common/strings";
import {
  generateFindTool,
  generateRetrieveTool,
  isAnswerToolType,
  isSummaryTool,
  transformReportToolDependencyToReduxTool,
} from "source/utils/matrix/tools";
import { SUMMARY_COL_NAME } from "source/constants";
import { scrollToColumn } from "../../utils/matrix/grid/gridUtilLayer";
import { ReduxTool } from "source/components/matrix/types/tools.types";
import { MatrixGridApi } from "source/components/matrix/types/grid.types";
import { queryClient } from "pages/_app";
import { docsKeys } from "source/api/docs/docsKeyFactory";
import { ssrmSetLoadingCellsForColumns } from "source/utils/matrix/ltl/lightningTransactionLayer";
import { RefreshServerSideParams } from "ag-grid-community";

export const handleLoadHistoryMessage =
  (res: MCLoadHistoryPayload): AppThunk =>
  (dispatch) => {
    // Remove spaces leftover from where citations were previously rendered in the message
    const transformedItems = res.msgs.map((res) => ({
      id: uuidv4(),
      content: removeSpacesBeforePunctuation(res.msg),
      role: res.role,
      steps: [],
      isFromPreviousSession: true,
    }));

    dispatch(addMatrixChatMessages(transformedItems));
    dispatch(setIsHistoryLoaded(true));
  };

export const handleMCMessage =
  (res: MCMessageResponsePayload): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const activeReport = getActiveReport(state);

    dispatch(upsertMatrixChatMessage({ ...res, role: "assistant" }));

    // If error, set Matrix Chat to error state
    if (res.error) {
      dispatch(setIsMatrixChatLoading(false));
      return;
    }

    // If first message, set Matrix chat to loading state
    if (res.loading && activeReport) {
      dispatch(setIsMatrixChatLoading(true));
      return;
    }

    // If last message, set loading to false and upsert citations
    if (!res.loading && activeReport) {
      dispatch(addCitationsFromMessage(res.content));
      dispatch(setIsMatrixChatLoading(false));
      return;
    }
  };

export const handleMCAskToolEvent =
  (res: MCAskToolIncomingResponsePayload, gridApi?: MatrixGridApi): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const activeReport = getActiveReport(state);
    const toolMap = getDisplayReportToolMap(state);

    logReportResponse({
      event: MatrixChatWebsocketEvent.MC_ASK_TOOL_INCOMING,
      reportId: res.report_id,
      requestId: res.request_id,
    });

    // Sanity check
    if (
      !activeReport?.id ||
      !isReportsTableResponse(res.report_id, activeReport?.id)
    ) {
      return;
    }

    const findToolXCoords = new Set<ReduxTool["static_column_id"]>();

    // store the columns that were added
    dispatch(
      setMostRecentMatrixChatActions({
        columns_added: res.static_column_ids,
      })
    );

    // Pull out any new column ids from the response
    const newColumnsIds = res.tools.reduce<string[]>((acc, tool) => {
      if (!toolMap || !tool?.static_column_id) {
        return acc;
      }

      if (toolMap[tool?.static_column_id]) {
        return acc;
      }

      return [...acc, tool.static_column_id];
    }, []);

    res.static_column_ids.forEach((static_column_id) => {
      const toolDependency = res.tools.find(
        (tool) => tool.static_column_id === static_column_id
      );

      if (toolDependency && isAnswerToolType(toolDependency.tool)) {
        const reportTool: ReduxTool = generateFindTool({
          tool: {
            ...toolDependency,
            name: res.column_names.find(
              ({ static_column_id }) =>
                static_column_id === toolDependency.static_column_id
            )?.name,
            prompt: res.prompts.find(
              ({ static_column_id }) =>
                static_column_id === toolDependency.static_column_id
            )?.prompt,
          },
        });

        findToolXCoords.add(static_column_id);

        dispatch(upsertReportTool({ tool: reportTool, tabId: res.tab_id }));
      }
    });

    // Update the client side grid with the new loading cells
    if (gridApi) {
      ssrmSetLoadingCellsForColumns(gridApi, newColumnsIds);
    }

    // Ensure the column is visible, delay is necessary to ensure the column transaction is complete
    // (although this will probably break if we do async transactions...)
    delay(() => {
      // @shirley @alex migration: TODO
      const firstNewColId = res.static_column_ids[0];
      const allColumns = gridApi?.getColumns();

      if (allColumns && firstNewColId) {
        scrollToColumn({ gridApi, columnKey: firstNewColId });
      }
    }, 0);
  };

export const handleMCGetToolEvent =
  (
    res: MCGetToolIncomingResponsePayload,
    matrixId?: string,
    refreshServerSide?: (params?: RefreshServerSideParams) => void
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const toolMap = getDisplayReportToolMap(state);

    logReportResponse({
      event: MatrixChatWebsocketEvent.MC_GET_TOOL_INCOMING,
      reportId: res.report_id,
      requestId: res.request_id,
    });

    if (!matrixId || !isReportsTableResponse(res.report_id, matrixId)) return;

    const retrieveToolCoords: number[] = [];
    const retrieveTools = res.tools.filter(({ tool }) => tool === "retrieve");

    if (retrieveTools.length !== 1) {
      logger.error(
        "Invalid number of retrieve tools recieved in get_tool_incoming event",
        {
          reportId: matrixId,
          tools: res.tools,
        }
      );
      return;
    }
    if (!retrieveTools?.[0]) {
      logger.error(
        "Invalid retrieve tool recieved in get_tool_incoming event",
        {
          reportId: matrixId,
          tools: res.tools,
        }
      );
      return;
    }

    const findToolCols: string[] = [];
    const newDocIds = res.new_doc_ids ?? [];

    res.tools.forEach((curTool) => {
      if (curTool.tool === "retrieve") {
        const retrieveColumnId = curTool.static_column_id;
        if (!retrieveColumnId) return;
        const retrieveTool = toolMap?.[retrieveColumnId];
        if (!retrieveTool) return;

        const tool: ReduxTool = generateRetrieveTool({
          ...retrieveTool,
          ...curTool,
        });
        dispatch(upsertReportTool({ tool, tabId: res.tab_id }));
      } else if (isSummaryTool(curTool.tool)) {
        if (curTool.static_column_id)
          findToolCols.push(curTool.static_column_id);

        const existingPrecomputedSummaryTool = curTool.static_column_id
          ? toolMap?.[curTool.static_column_id]
          : undefined;

        // store the columns that were added
        !existingPrecomputedSummaryTool &&
          !!curTool.static_column_id &&
          dispatch(
            setMostRecentMatrixChatActions({
              columns_added: [curTool.static_column_id],
            })
          );
        // if a new precomputed_summary tool was sent, update it in redux and create the corresponding column
        const tool = generateFindTool({
          tool: {
            ...existingPrecomputedSummaryTool,
            ...transformReportToolDependencyToReduxTool(curTool),
            name: SUMMARY_COL_NAME,
            tool: "precomputed_summary",
          },
        });
        dispatch(upsertReportTool({ tool, tabId: res.tab_id }));
      } else if (isAnswerToolType(curTool.tool, false)) {
        if (curTool.static_column_id)
          findToolCols.push(curTool.static_column_id);

        const tool = generateFindTool({
          tool: {
            ...curTool,
            coordinates: [
              ...(curTool.coordinates || []),
              ...retrieveToolCoords,
            ],
          },
        });
        dispatch(upsertReportTool({ tool, tabId: res.tab_id }));
      }
    });

    // If we got back new docs then show the undo button
    if (newDocIds.length > 0) {
      dispatch(
        setMostRecentMatrixChatActions({
          documents_added: newDocIds,
        })
      );

      // Refetch the documents from the server
      queryClient.invalidateQueries(docsKeys.batchDocs(matrixId));

      // Refresh the ag-grid serverside
      // TODO: @Ellis we have the new docIds, so we can do a client side update for immediate feedback
      if (refreshServerSide) {
        refreshServerSide();
      }
    }
  };

export const handleMCColumnConversionEvent =
  (res: MCColumnConversionPayload): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const toolMap = getDisplayReportToolMap(state);
    const { numeric_static_column_ids, date_static_column_ids } = res;
    const colUpdates = numeric_static_column_ids.map((colId) => {
      const headerName = toolMap?.[colId]?.name;
      return {
        staticColumnId: colId,
        name: headerName ?? "",
        newType: "float",
      } as MatrixColConversionRequest;
    });
    date_static_column_ids.forEach((colId) => {
      const headerName = toolMap?.[colId]?.name;
      colUpdates.push({
        staticColumnId: colId,
        name: headerName ?? "",
        newType: "datetime.date",
      } as MatrixColConversionRequest);
    });
    dispatch(setColumnTypeConversionRequests(colUpdates));
  };

export const handleMCCitationInfoEvent =
  (res: MCCitationInfoResponsePayload): AppThunk =>
  (dispatch) => {
    dispatch(setCitationIdMap(res));
  };

export const handleMCSizeError = (): AppThunk => (dispatch) => {
  dispatch(setTokenLimitError(true));
  dispatch(
    upsertToast({
      id: "matrixChatTokenLimitError",
      primaryText: "Start a new chat",
      secondaryText: "Your token limit for this chat session has been reached.",
      icon: "warning",
    })
  );
};

export const handleMCFastBuildDocIds =
  (
    res: MCFastBuildDocIDsPayload,
    matrixId?: string,
    refreshServerSide?: (params?: RefreshServerSideParams) => void
  ): AppThunk =>
  (_dispatch, _getState) => {
    // Refetch the documents from the server
    queryClient.invalidateQueries(docsKeys.batchDocs(matrixId));

    // Refresh the ag-grid serverside
    // TODO: @Ellis we have the new docIds, so we can do a client side update for immediate feedback
    if (refreshServerSide) {
      refreshServerSide();
    }
  };
