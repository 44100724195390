import { FilterModel, IServerSideGroupSelectionState } from "ag-grid-community";
import { useContext } from "react";
import {
  SocketResponseError,
  SocketStatusCode,
} from "source/components/matrix/contexts/protocol.types";
import { ReportsWebsocketContext } from "source/components/matrix/contexts/ReportsWebsocketContext";
import {
  SSRMGetMatrixCellsStatusResponse,
  SSRMRow,
} from "source/components/matrix/types/cells.types";

type GetRowByIdRequest = {
  row_id: string;
};

type GetRowByIdResponse = {
  row: SSRMRow;
};

type GetRowIndexByDocIdRequest = {
  repo_doc_id: string;
};

type GetRowIndexByDocIdResponse = {
  index: number;
};

type AddNewDocumentsRequest = {
  doc_ids: string[];
};

type AddNewDocumentsResponse = {
  successful_doc_ids: string[];
};

type GetRowIndexByCellIdRequest = {
  cell_id: string;
  sort_column_id: string | undefined;
  sort_direction: string | undefined;
  // sort_column_type: TODO?,
  filter_model: FilterModel;
  full_matrix_search: string | undefined;
  // group_keys: TODO?
};

type GetRowIndexByCellIdResponse = {
  index: number;
};

type DeleteRowsBySelectionStateRequest = {
  selection_state?: IServerSideGroupSelectionState;
  filter_model?: FilterModel;
  full_matrix_search: string | undefined;
  sort_column_id: string | undefined;
  sort_direction: string | undefined;
};

type DeleteRowsBySelectionStateResponse = {
  row_ids: string[];
};

type GetDocIdsBySelectionStateRequest = {
  selection_state?: IServerSideGroupSelectionState;
  filter_model?: FilterModel;
  full_matrix_search: string | undefined;
  sort_column_id: string | undefined;
  sort_direction: string | undefined;
};

type GetDocIdsBySelectionStateResponse = {
  doc_ids: string[];
};

// Helper fn to check if a socket error is non-recoverable
export const getIsSocketErrorFatal = (error: any) => {
  if (error instanceof SocketResponseError) {
    if (error.socketResponse.status === SocketStatusCode.SERVICE_UNAVAILIBLE) {
      return false;
    }

    if (
      error.socketResponse.status === SocketStatusCode.CLIENT_CLOSED_REQUEST
    ) {
      return false;
    }

    if (error.socketResponse.status === SocketStatusCode.TIMEOUT) {
      return false;
    }

    return true;
  }

  return true;
};

export const useMatrixSocketApi = () => {
  const { invokeRest } = useContext(ReportsWebsocketContext);

  const getRowById = async (rowId?: string) => {
    // Sanity check for TS
    if (!rowId) return;

    // @ts-ignore: Relax bruh
    const response = await invokeRest<GetRowByIdRequest, GetRowByIdResponse>(
      "/ssrm/get-row",
      {
        row_id: rowId,
      }
    );

    return response?.body?.row;
  };

  const getMatrixCellsStatus = async () => {
    const response = await invokeRest<null, SSRMGetMatrixCellsStatusResponse>(
      "/ssrm/get-matrix-cells-status"
    );

    return response?.body;
  };

  const getRowIndexByDocId = async (docId: string) => {
    const response = await invokeRest<
      GetRowIndexByDocIdRequest,
      GetRowIndexByDocIdResponse
    >("/ssrm/get-row-index-by-doc-id", {
      repo_doc_id: docId,
    });

    return response?.body?.index;
  };

  const addNewDocuments = async (docIds: string[]) => {
    const response = await invokeRest<
      AddNewDocumentsRequest,
      AddNewDocumentsResponse
    >("/ssrm-partials/add-new-documents", {
      doc_ids: docIds,
    });

    return response?.body;
  };

  const getRowIndexByCellId = async (request: GetRowIndexByCellIdRequest) => {
    const response = await invokeRest<
      // @ts-ignore: Relax bruh
      GetRowIndexByCellIdRequest,
      GetRowIndexByCellIdResponse
    >("/ssrm/get-row-index-by-cell-id", request);

    return response?.body?.index;
  };

  const deleteRowsBySelectionState = async (
    request: DeleteRowsBySelectionStateRequest
  ) => {
    const response = await invokeRest<
      // @ts-ignore: Relax bruh
      DeleteRowsBySelectionStateRequest,
      DeleteRowsBySelectionStateResponse
    >("/ssrm-partials/delete-selected-documents", request);

    return response?.body?.row_ids;
  };

  const getDocIdsBySelectionState = async (
    request: GetDocIdsBySelectionStateRequest
  ) => {
    const response = await invokeRest<
      // @ts-ignore: Relax bruh
      GetDocIdsBySelectionStateRequest,
      GetDocIdsBySelectionStateResponse
    >("/ssrm/get-doc-ids-by-selection-state", request);

    return response?.body;
  };

  return {
    getRowById,
    getRowIndexByDocId,
    getMatrixCellsStatus,
    addNewDocuments,
    getRowIndexByCellId,
    getDocIdsBySelectionState,
    deleteRowsBySelectionState,
  };
};
