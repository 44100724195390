import { BackendReportFilter, RepoType, DocumentType } from "source/Types";
import {
  AnswerToolType,
  GroupFindToolType,
  PaddingToolType,
  PrecomputedFastFindToolType,
  PrecomputedSummaryToolType,
  ReportToolType,
  RetrieveToolType,
  ToolOutputType,
} from "./tools.types";
import { ColumnVO, FilterModel } from "ag-grid-community";

export interface EvidenceQuoteBase {
  kind: "table" | "text_excerpt" | "excel";
}

export interface TableEvidenceQuote extends EvidenceQuoteBase {
  kind: "table";
  table_id: string;
}

export interface TextEvidenceQuote extends EvidenceQuoteBase {
  kind: "text_excerpt";
  quote: string;
  is_validated?: boolean;
  can_be_highlighted?: boolean;
  cleaned_quote?: string;
}

export interface ExcelEvidenceQuote extends EvidenceQuoteBase {
  kind: "excel";
  cell_ids: string[];
  tab_number: number;
}

export type EvidenceQuoteV2 =
  | TableEvidenceQuote
  | TextEvidenceQuote
  | ExcelEvidenceQuote;

/** Cell Result Types */
export type FindAnswerType = {
  answer?: string;
  answer_arr?: string[];
  answer_details?: FindResultDetailsType;
  score?: string;
  answer_type: ToolOutputType;
  source_index?: number;
  // Some Columns give one quote, some give multiple. Only ONE of these will be populated
  evidence_quote?: string;
  evidence_quotes?: string[];
  evidence_quotes_v2?: EvidenceQuoteV2[];
};

export type FindResultType = {
  answers: FindAnswerType[];
  doc_id: string;
  user_edited?: boolean;
};

export type GroupFindResultType = {
  answer: string;
  rule: BackendReportFilter;
  source_cells: {
    [row: string]: string;
  };
};

/**
 * DocumentResultType contains all the fields of a DocumentType + an optional error
 * field. Used to populate fast build errors, which exist separately from actual
 * errors during reports execution
 */
export type DocumentResultType = DocumentType & { fast_build_error?: string };

export type FindResultDetailsType = {
  doc_id: string;
  passage_id: string;
  context: string;
  context_char_start: number;
  context_char_end: number;
  highlights: any[];
  best_highlight: string;
  scored_ngrams: Record<number, any>;
  doc_viewer: any[];
  doc_viewer_url?: string;
};

export type GroupingContent = {
  id: string;
  x: number;
  rule: BackendReportFilter;
  error: string | null;
  loading?: boolean;
};

export type ReportCellResultType =
  | DocumentResultType
  | FindResultType
  | GroupFindResultType;

/** Cell Types */

/**
 * @deprecated
 * Base cell type that is present in all cell models
 */
interface BaseHydratedCellType {
  id: string;
  row_id?: string;
  doc_id?: string;
  loading?: boolean;
  loading_step_message?: string;
  // Non-null when there is an issue populating the cell into Redis on backend.
  // a fast build doc had an error
  error?: string;
  /**
   * @deprecated Legacy field. Do not read, only persist.
   */
  x?: number;
  y: number;
  // TODO: Me thinks we can remove disabled as well
  disabled?: boolean;
  tool: ReportToolType;
  result?: ReportCellResultType | undefined;

  static_column_id: string;
  versioned_column_id?: string;
}

/**
 * @deprecated
 */
export interface HydratedDocumentCellType extends BaseHydratedCellType {
  tool: RetrieveToolType;
  x: 0;
}

/**
 * @deprecated
 */
export interface HydratedAnswerCellType extends BaseHydratedCellType {
  tool:
    | AnswerToolType
    | PrecomputedSummaryToolType
    | PrecomputedFastFindToolType;
  result?: FindResultType;
}

/**
 * @deprecated
 */
interface HydratedGroupCellType extends BaseHydratedCellType {
  tool: GroupFindToolType;
  result?: GroupFindResultType;
}

/**
 * @deprecated
 */
interface PaddingCellType extends BaseHydratedCellType {
  tool: PaddingToolType;
}

/**
 * @deprecated
 */
export type HydratedReportCellType =
  | HydratedAnswerCellType
  | HydratedGroupCellType
  | HydratedDocumentCellType
  | PaddingCellType;

/**
 * @deprecated
 * Row Object: Used in AG Grid to represent a document and all of its relevant cell column outputs
 */
export type ReportTableRow = {
  rowId?: string;
  cells: { [colId: string]: HydratedReportCellType };
  document?: DocumentType;
};

/**
 * @deprecated
 */
export type ReportCellType = Omit<
  HydratedReportCellType,
  "result" | "loading" | "error"
>;

// Full union type to be exported downstream
// TODO: Make this not extend HydratedReportCellType
export type CellContent = Omit<HydratedReportCellType, "x" | "y" | "disabled">;

export type RepoMapType = Record<string, RepoType>;

// SSRM types
export type SSRMGetRowsRequest = {
  start_idx?: number;
  end_idx?: number;
  sort_column_id?: string;
  sort_direction?: string;
  filter_model?: FilterModel | null;
  row_group_columns?: ColumnVO[] | null;
  group_keys?: string[];
  full_matrix_search?: string;
};

export type SSRMRow = {
  id: string;
  cells: Record<string, CellContent>;
  document?: DocumentType;
};

export type SSRMGetRowsResult = {
  rows: SSRMRow[];
  row_count: number;
};

export type SSRMGetMatrixCellsStatusResponse = {
  loading_cell_count: number;
  error_cell_count: number;
  total_cell_count: number;
  has_unrun_rows: boolean;
};

export type SSRMRunMoreRowsRequest = {
  sort_column_id?: string;
  sort_direction?: string;
  filter_model?: FilterModel | null;
};

export enum CellLoadingStep {
  READING_DOCUMENT = 0,
  SYNTHESIZING_ANSWER = 1,
}
